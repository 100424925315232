import { useState } from 'react';
import { Button } from '../../../../components/Button/Button';
import { FilterButton } from '../../../../components/common/buttons/iconButtons/FilterButton';
import {
  SortButton,
  SortDirection,
} from '../../../../components/common/buttons/iconButtons/SortButton';
import { Search } from '../../../../components/common/search/Search';
import { Cell } from '../../../../components/common/table/Cell/Cell';
import { Header } from '../../../../components/common/table/Header/Header';
import { HeaderCell } from '../../../../components/common/table/HeaderCell/HeaderCell';
import { Row } from '../../../../components/common/table/Row/Row';
import { AlertIcon } from '../../../../icons/AlertIcon';
import { TreatmentPlan } from '../../../../shared/types/types';
import './TreatmentPlansList.scss';

export interface TreatmentPlansListProps {
  data: TreatmentPlan[];
  onSelect: (planId: string) => void;
}

export type TreatmentPlanFilterType =
  | 'none'
  | 'plan-type'
  | 'plan-name'
  | 'last-name'
  | 'medicalId';

export function TreatmentPlansList({
  data,
  onSelect,
}: TreatmentPlansListProps) {
  const [searchValue, setSearchValue] = useState('');
  const [selectedTreatmentPlan, setSelectedTreatmentPlan] =
    useState<string>('');
  const [sortDirectionFirstName, setSortDirectionFirstName] =
    useState<SortDirection>('ascending');
  const [sortDirectionLastName, setSortDirectionLastName] =
    useState<SortDirection>('ascending');

  const [sortDirectionMedicalId, setSortDirectionMedicalId] =
    useState<SortDirection>('ascending');

  const handleResetFiltersClick = () => {
    setSearchValue('');
  };

  const filterPatientRecord = (pat: TreatmentPlan): boolean => {
    return true;
  };

  const handleFilterDateClick = () => {};

  const handleSortFirstNameClick = () => {
    setSortDirectionFirstName(
      sortDirectionFirstName === 'ascending'
        ? 'descending'
        : 'ascending'
    );
  };

  const handleSortLastNameClick = () => {
    setSortDirectionLastName(
      sortDirectionLastName === 'ascending'
        ? 'descending'
        : 'ascending'
    );
  };

  const handleSortMedicalIdClick = () => {
    setSortDirectionMedicalId(
      sortDirectionMedicalId === 'ascending'
        ? 'descending'
        : 'ascending'
    );
  };

  return (
    <div
      className="treatment-plans-list-container"
      data-cy="treatment-plans-list"
    >
      <div className="title">plans</div>
      <div className="search-plans-container">
        <Search
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.currentTarget.value);
          }}
          placeholder="Search for treatment plans"
        />
        <Button
          label="Reset Filters"
          icon={<AlertIcon />}
          iconPlacement={'left'}
          onClick={handleResetFiltersClick}
          className={`reset-filters-button ${
            searchValue !== ''
              ? 'active-reset-button'
              : 'inactive-reset-button'
          }`}
        />
      </div>
      <table className="table-container">
        <Header>
          <HeaderCell>
            <div>plan type</div>
            <FilterButton
              onClick={handleFilterDateClick}
              width={24}
              height={24}
            />
          </HeaderCell>
          <HeaderCell>
            <div>plan name</div>
            <SortButton
              onClick={handleSortFirstNameClick}
              width={24}
              height={24}
              sortDirection={sortDirectionFirstName}
            />
          </HeaderCell>
          <HeaderCell>
            <div>last name</div>
            <SortButton
              onClick={handleSortLastNameClick}
              width={24}
              height={24}
              sortDirection={sortDirectionLastName}
            />
          </HeaderCell>
          <HeaderCell>
            <div>medical ID</div>
            <SortButton
              onClick={handleSortMedicalIdClick}
              width={24}
              height={24}
              sortDirection={sortDirectionMedicalId}
            />
          </HeaderCell>
        </Header>
        <tbody className="body-container">
          {data.length > 0 &&
            data
              .filter(filterPatientRecord)
              .map((data: TreatmentPlan, index: number) => {
                const handleRowClick = () => {
                  if (data._id === selectedTreatmentPlan) {
                    setSelectedTreatmentPlan('');
                    onSelect('');
                  } else {
                    setSelectedTreatmentPlan(data._id);
                    onSelect(data._id);
                  }
                };
                return (
                  <Row
                    index={index}
                    key={data._id}
                    selected={data._id === selectedTreatmentPlan}
                    onClick={handleRowClick}
                    data-cy={`treatment-plan-row-${index}`}
                  >
                    <Cell>{data.plan.type}</Cell>
                    <Cell>{data.plan.name}</Cell>
                    <Cell>{data.patient.lastName}</Cell>
                    <Cell>{data.patient.medicalId}</Cell>
                  </Row>
                );
              })}
        </tbody>
      </table>
    </div>
  );
}

export type SortDirectionType = 'asc' | 'desc';
