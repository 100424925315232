import { CarrotDown } from '../../shared/assets/carrot-down';
import { SyringeIcon } from '../../shared/assets/syringe-teal';
import './Navbar.scss';

export const InfusionButton = () => {
  return (
    <div className="navbar-item nav-dropdown">
      <SyringeIcon fill="#0091b2" />
      <span className="infusion-dropdown">Infusion</span>
      <CarrotDown />
    </div>
  );
};
