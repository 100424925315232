import { IconTypeProps } from '../../shared/types/types';
import '../../shared/styles/variables.scss';

export function AlignLeft({
  width = 16,
  height = 16,
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_639_12068)">
        <path
          d="M0.666666 4.00002H15.3333C15.5101 4.00002 15.6797 3.92978 15.8047 3.80476C15.9298 3.67973 16 3.51016 16 3.33335C16 3.15654 15.9298 2.98697 15.8047 2.86195C15.6797 2.73692 15.5101 2.66669 15.3333 2.66669H0.666666C0.489855 2.66669 0.320286 2.73692 0.195262 2.86195C0.0702379 2.98697 0 3.15654 0 3.33335C0 3.51016 0.0702379 3.67973 0.195262 3.80476C0.320286 3.92978 0.489855 4.00002 0.666666 4.00002Z"
          fill={color}
        />
        <path
          d="M0.666666 7.33333H10C10.1768 7.33333 10.3464 7.26309 10.4714 7.13807C10.5964 7.01304 10.6667 6.84348 10.6667 6.66667C10.6667 6.48985 10.5964 6.32029 10.4714 6.19526C10.3464 6.07024 10.1768 6 10 6H0.666666C0.489855 6 0.320286 6.07024 0.195262 6.19526C0.0702379 6.32029 0 6.48985 0 6.66667C0 6.84348 0.0702379 7.01304 0.195262 7.13807C0.320286 7.26309 0.489855 7.33333 0.666666 7.33333Z"
          fill={color}
        />
        <path
          d="M10 12.6667H0.666666C0.489855 12.6667 0.320286 12.7369 0.195262 12.8619C0.0702379 12.987 0 13.1565 0 13.3334C0 13.5102 0.0702379 13.6797 0.195262 13.8048C0.320286 13.9298 0.489855 14 0.666666 14H10C10.1768 14 10.3464 13.9298 10.4714 13.8048C10.5964 13.6797 10.6667 13.5102 10.6667 13.3334C10.6667 13.1565 10.5964 12.987 10.4714 12.8619C10.3464 12.7369 10.1768 12.6667 10 12.6667Z"
          fill={color}
        />
        <path
          d="M15.3333 9.33331H0.666666C0.489855 9.33331 0.320286 9.40355 0.195262 9.52858C0.0702379 9.6536 0 9.82317 0 9.99998C0 10.1768 0.0702379 10.3464 0.195262 10.4714C0.320286 10.5964 0.489855 10.6667 0.666666 10.6667H15.3333C15.5101 10.6667 15.6797 10.5964 15.8047 10.4714C15.9298 10.3464 16 10.1768 16 9.99998C16 9.82317 15.9298 9.6536 15.8047 9.52858C15.6797 9.40355 15.5101 9.33331 15.3333 9.33331Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_639_12068">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
