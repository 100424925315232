import { IconButtonProps } from ".";
import { SearchOutlined } from "../../../../icons/SearchOutlined";
import "./IconButton.scss";

export function SearchButton({onClick, width, height, color}:IconButtonProps){
    return(
        <div className={'icon-button'} onClick={onClick}>
            <SearchOutlined width={width} height={height} color={color}/>
        </div>
    )
}