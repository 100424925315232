import { useAuth0 } from '@auth0/auth0-react';

import { ReactElement } from 'react';

import { Logout } from '../../shared/assets/logout';
import { Button } from '../Button/Button';
import './TemporaryLoginPopup.scss';

const TemporaryLoginPopup = (): ReactElement | null => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (isAuthenticated) return null;
  else
    return (
      <div className="temporary-login-container">
        <div className="temporary-login-parent">
          <div className="temporary-login-header">
            <span>Please log in to continue</span>
          </div>
          <div className="popup-buttons">
            <Button
              label="Log In"
              className="login-button"
              icon={<Logout fill="#0091b2" />}
              iconPlacement="left"
              onClick={loginWithRedirect}
            />
          </div>
        </div>
      </div>
    );
};

export default TemporaryLoginPopup;
