import { IconTypeProps } from '../shared/types/types';
import '../shared/styles/variables.scss';

export function SpinnerIcon({
  width = 91,
  height = 94,
  color = 'currentColor',
  ...rest
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 91 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M45.497 21.0451C62.3394 25.5796 65.8575 40.9366 65.5104 48.1085C65.5104 48.1085 65.422 49.1624 66.2615 47.7926C67.1011 46.4229 72.206 34.7605 67.4208 21.9711C65.3907 18.143 58.2706 2.03503 39.2614 0.595724C22.3386 -0.685617 12.3307 8.41456 7.82376 13.0098C3.31682 17.6051 2.16847 24.5522 4.66264 27.0753C7.15681 29.5984 10.1434 28.1229 14.9585 25.4408C19.7736 22.7587 28.6545 16.5106 45.497 21.0451Z"
        fill={color}
      />
      <path
        d="M18.0172 56.6402C13.4405 39.8092 24.9284 29.0276 31.2968 25.711C31.2968 25.711 32.2515 25.2559 30.6453 25.2215C29.0391 25.1872 16.3938 26.6594 7.76216 37.2406C5.47994 40.924 -4.84009 55.1949 3.50203 72.3363C10.9286 87.5963 23.8335 91.6502 30.0744 93.2252C36.3153 94.8001 42.8936 92.2888 43.8148 88.8627C44.736 85.4366 41.9559 83.6014 37.2118 80.7957C32.4677 77.99 22.594 73.4712 18.0172 56.6402Z"
        fill={color}
      />
      <path
        d="M63.0454 62.6415C50.9315 75.1907 35.7883 70.8435 29.6787 67.0714C29.6787 67.0714 28.7989 66.4844 29.5919 67.8816C30.3848 69.2788 38.1241 79.387 51.6326 81.3835C55.9179 81.8386 73.5075 83.0154 83.9601 67.0729C93.2654 52.8802 90.1399 39.7195 88.2973 33.5524C86.4546 27.3852 80.9292 23.0205 77.5146 23.9834C74.1 24.9464 73.9471 28.2741 73.9662 33.7858C73.9853 39.2974 75.1594 50.0924 63.0454 62.6415Z"
        fill={color}
      />
    </svg>
  );
}
