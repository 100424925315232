import { useState } from 'react';
import { TreatmentPlan } from '../../../../shared/types/types';
import { TreatmentPlansNavBar } from './navBar/TreatmentPlansNavBar';
import { PlanOverview } from './planOverview/PlanOverview';
import { SetupNotes } from './setupNotes/SetupNotes';
import './TreatmentPlansContent.scss';
import { TreatmentReport } from './treatmentReport/TreatmentReport';

export type TreatmentPlansSection =
  | 'plans-overview'
  | 'setup-notes'
  | 'treatment-report';

export interface TreatmentPlansContentProps {
  planData: TreatmentPlan;
}

export function TreatmentPlansContent({
  planData,
}: TreatmentPlansContentProps) {
  const [currentSection, setCurrentSection] =
    useState<TreatmentPlansSection>('plans-overview');

  const handlePlanOverviewClick = () => {
    setCurrentSection('plans-overview');
  };

  const handleSetupNotesClick = () => {
    setCurrentSection('setup-notes');
  };

  const handleTreatmentReportClick = () => {
    setCurrentSection('treatment-report');
  };

  return (
    <div
      className="treatment-plans-content-container"
      data-cy="treatment-plans-content"
    >
      <TreatmentPlansNavBar
        onPlanOverviewClick={handlePlanOverviewClick}
        onSetupNotesClick={handleSetupNotesClick}
        onTreatmentReportClick={handleTreatmentReportClick}
        currentSection={currentSection}
      />
      {currentSection === 'plans-overview' ? (
        <PlanOverview
          planData={planData}
          onApproveClick={() => {}}
          onCourseNameChange={() => {}}
          onStatusChange={() => {}}
          onUnapproveClick={() => {}}
        />
      ) : currentSection === 'setup-notes' ? (
        <SetupNotes />
      ) : currentSection === 'treatment-report' ? (
        <TreatmentReport />
      ) : (
        'Nav Error'
      )}
    </div>
  );
}
