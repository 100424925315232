import React from 'react';
import './SubNav.scss';
import { GlobalUserContext } from '../../shared/contexts/GlobalUserContext';
import { useContext } from 'react';
import { PatientRecord, SubNavProps } from '../../shared/types/types';

const getTemporaryTabData = (patientInfo: PatientRecord | null) => {
  const initialTabValue = [{ title: 'Patients', isActive: true }];
  let tabs = initialTabValue;
  if (patientInfo !== null) {
    const label =
      patientInfo.encrypt_givenName.slice(0, 1) +
      '.' +
      patientInfo.encrypt_familyName;
    tabs = [
      { title: 'Patients', isActive: false },
      { title: label, isActive: true },
    ];
  }

  return tabs;
};

const SubNav = ({ ...props }: SubNavProps) => {
  const globalUserState = useContext(GlobalUserContext);
  const { patientInfo } = globalUserState;

  /*Todo: to be replaced with a more dynamic approach once we figure out which component structure is going to be abstracted
   and figure out how to stop the re-render caused by the ContextProvider updates
  */
  const tabs = getTemporaryTabData(patientInfo);
  return <RenderSubNav tabs={tabs} />;
};

const RenderSubNav = (props: {
  tabs: Array<{ title: string; isActive: boolean }>;
}) => {
  const { tabs } = props;
  const tabsElements = tabs.map((tab, index) => {
    const activeTabStyle = tab.isActive
      ? { borderBottom: '2px solid #2cc0e8', color: '#2cc0e8' }
      : {};

    const closeTab =
      tabs.length > 1 && tab.isActive ? (
        <a className="subnav-tab-close" href={'/'}>
          X
        </a>
      ) : null;
    return (
      <span
        data-testid={'subnav-tab-title-' + index}
        key={tab.title}
        className="subnav-tab-title"
        style={activeTabStyle}
      >
        {tab.title}
        {closeTab}
      </span>
    );
  });
  return (
    <div data-testid="subnav-container" className="subnav-container">
      <div className="subnav-tab">{tabsElements}</div>
    </div>
  );
};
export default SubNav;
