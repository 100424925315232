import React from 'react';

export const Clock = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="23.999975"
      viewBox="0 0 24 23.999975"
      fill="none"
      version="1.1"
    >
      <path
        d="M 12,0 C 9.6266302,0 7.3065502,0.703788 5.3331602,2.02236 c -1.97339,1.31858 -3.51146,3.19272 -4.41970902,5.38544 -0.90825157,2.19271 -1.145892,4.6055 -0.682869,6.9333 0.463023,2.3278 1.60590802,4.4659 3.28413802,6.1442 1.67823,1.6782 3.81643,2.8211 6.1442,3.2841 C 11.9867,24.2324 14.3995,23.9948 16.5922,23.0865 18.7849,22.1783 20.6591,20.6402 21.9776,18.6668 23.2962,16.6934 24,14.3734 24,12 23.9966,8.81846 22.7312,5.76821 20.4815,3.51852 18.2318,1.26883 15.1815,0.00344108 12,0 Z m 0,22 c -1.9778,0 -3.9112098,-0.5865 -5.5556998,-1.6853 -1.64449,-1.0988 -2.92621,-2.6606 -3.68309,-4.4879 -0.75688,-1.8272 -0.95491,-3.8379 -0.56906,-5.7777 0.38585,-1.93981 1.33826,-3.72164 2.73679,-5.12017 C 6.3274602,3.53041 8.1092902,2.578 10.0491,2.19215 11.9889,1.80629 13.9996,2.00433 15.8268,2.7612 17.6541,3.51808 19.2159,4.79981 20.3147,6.4443 21.4135,8.08879 22,10.0222 22,12 21.9971,14.6513 20.9426,17.1931 19.0679,19.0678 17.1931,20.9426 14.6513,21.9971 12,22 Z"
        fill={props.fill}
        id="path115"
      />
      <g
        style={{ fill: `${props.fill}` }}
        id="g211"
        transform="translate(8.971508,7.3553919)"
      >
        <path
          d="M 5.00042,0 C 4.73521,0 4.48085,0.105357 4.29332,0.292893 4.10578,0.480429 4.00043,0.734782 4.00043,0.999998 V 5.32499 l -3.370996,2.112 C 0.404,7.57782 0.243744,7.80243 0.18392,8.06142 0.124096,8.32041 0.169605,8.59255 0.310434,8.81799 0.451264,9.04342 0.675879,9.20368 0.934866,9.2635 1.19385,9.32332 1.466,9.27782 1.69143,9.13699 l 3.83999,-2.4 C 5.67651,6.64609 5.79582,6.51945 5.87794,6.36922 5.96006,6.21899 6.00223,6.05019 6.00042,5.87899 V 0.999998 C 6.00042,0.734782 5.89506,0.480429 5.70753,0.292893 5.51999,0.105357 5.26564,0 5.00042,0 Z"
          fill={props.fill}
          id="path202"
        />
      </g>
    </svg>
  );
};
