import { IconTypeProps } from '../shared/types/types';
import '../shared/styles/variables.scss';

export function XClose({
  width = 25,
  height = 24,
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5408 0.292786C24.3532 0.105315 24.0989 0 23.8338 0C23.5686 0 23.3143 0.105315 23.1268 0.292786L12.8338 10.5858L2.54077 0.292786C2.35324 0.105315 2.09894 0 1.83377 0C1.56861 0 1.3143 0.105315 1.12677 0.292786C0.9393 0.480314 0.833984 0.734622 0.833984 0.999786C0.833984 1.26495 0.9393 1.51926 1.12677 1.70679L11.4198 11.9998L1.12677 22.2928C0.9393 22.4803 0.833984 22.7346 0.833984 22.9998C0.833984 23.2649 0.9393 23.5193 1.12677 23.7068C1.3143 23.8943 1.56861 23.9996 1.83377 23.9996C2.09894 23.9996 2.35324 23.8943 2.54077 23.7068L12.8338 13.4138L23.1268 23.7068C23.3143 23.8943 23.5686 23.9996 23.8338 23.9996C24.0989 23.9996 24.3532 23.8943 24.5408 23.7068C24.7282 23.5193 24.8336 23.2649 24.8336 22.9998C24.8336 22.7346 24.7282 22.4803 24.5408 22.2928L14.2478 11.9998L24.5408 1.70679C24.7282 1.51926 24.8336 1.26495 24.8336 0.999786C24.8336 0.734622 24.7282 0.480314 24.5408 0.292786Z"
        fill={color}
      />
    </svg>
  );
}
