import { ReactNode } from 'react';
import './StyleControlButton.scss';

export interface StyleControlButtonProps {
  active: boolean;
  icon: ReactNode;
  onToggle: (style: string) => void;
  style: string;
  label: string;
}

export function StyleControlButton({
  style,
  onToggle,
  active,
  icon,
  label,
}: StyleControlButtonProps) {
  const handleToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    onToggle(style);
  };

  return (
    <div
      className={`style-control-container ${active ? ' active' : ''}`}
      onMouseDown={handleToggle}
    >
      {icon ?? label}
    </div>
  );
}
