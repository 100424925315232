import { FlexContainer } from '../../components/FlexContainer/FlexContainer';
import { GridContainer } from '../../components/GridContainer/GridContainer';
import { Card } from '../../components/Card/Card';
import { fetchAuthenticated } from '../../controllers';
import { CAS_BACK_END_API_URL } from '../../constants';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { PatientRecord } from '../../shared/types/types';
import { PatientsAjaxResult } from '../../components/Authentication/landingPage/PatientList/PatientList';
import { decryptObject } from '../../components/Encryption/obfuscationHandler';
import { GlobalUserContext } from '../../shared/contexts/GlobalUserContext';

interface PatientBoxContainerProps {
  patientId: string;
}

interface PatientCardProps {
  patientData: PatientRecord;
}

const ErrorInfo = () => <>Error! Patient Info Not found</>;
const LoadingBox = () => <>Loading...</>;
const PatientCard = ({ patientData }: PatientCardProps) => {
  const {
    encrypt_givenName,
    encrypt_familyName,
    encrypt_dateOfBirth,
    encrypt_medicalId,
    encrypt_gender,
  } = patientData;

  const body = (
    <>
      <img
        style={{
          display: 'inline-block',
          width: '100px',
          height: '100px',
          borderRadius: '50%',
          objectFit: 'cover',
        }}
        src="https://via.placeholder.com/100x100"
        alt="profile-pic"
      />
      <FlexContainer>
        <GridContainer
          column={7}
          row={2}
          customColumnTemplate="1fr auto 1fr auto 1fr auto 1fr"
        >
          <div>
            <strong>{encrypt_givenName}</strong>
          </div>
          <div>
            <strong>{encrypt_familyName}</strong>
          </div>
          <div>
            <strong>Profile Type:</strong>&nbsp;
          </div>
          <div>
            <strong>Patient Status:</strong>&nbsp;
          </div>
          <span>Active</span>
          <span>Patient</span>
          <div>
            <strong>Gender:</strong>&nbsp;
          </div>
          <div>
            <strong>Date of Birth:</strong>&nbsp;
          </div>
          <span>{encrypt_gender}</span>
          <span>{encrypt_dateOfBirth}</span>
          <div>
            <strong>Medical ID:</strong>&nbsp;
          </div>
          <div></div>
          <span>{encrypt_medicalId}</span>
        </GridContainer>
      </FlexContainer>
    </>
  );

  return (
    <div style={{ width: '100%' }}>
      <Card title={'PATIENT INFORMATION'} body={body} />
    </div>
  );
};

const PatientBoxContainer = memo(
  ({ patientId }: PatientBoxContainerProps) => {
    const [patientData, setPatientData] =
      useState<PatientRecord | null>(null);

    const [apiData, setApiData] = useState<PatientsAjaxResult>();
    const [serverError, setServerError] = useState();
    const { token } = useContext(GlobalUserContext);

    const getPatientRecord = useCallback(async () => {
      const url = CAS_BACK_END_API_URL + '/patients/' + patientId;
      const config: RequestInit = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetchAuthenticated<PatientsAjaxResult>(
        url,
        config
      );
      if (response.ok) {
        setApiData(response?.parsedBody);
      }
    }, [patientId, token]);

    useEffect(() => {
      try {
        getPatientRecord();
      } catch (error: any) {
        setServerError(error);
      }
    }, [getPatientRecord]);

    useEffect(() => {
      async function decryptData() {
        if (apiData?.result?.patients) {
          try {
            await decryptObject(
              apiData.result.patients[0],
              token,
              null
            );
            setPatientData(apiData.result.patients[0]);
          } catch {
            setPatientData(null);
          }
        } else {
          setPatientData(null);
        }
      }
      decryptData();
    }, [apiData, token]);

    return (
      <>
        {patientData ? (
          <PatientCard patientData={patientData} />
        ) : serverError ? (
          <ErrorInfo />
        ) : (
          <LoadingBox />
        )}
      </>
    );
  }
);

const PatientBox = ({ patientId }: { patientId: string }) => {
  return <PatientBoxContainer patientId={patientId} />;
};

export default PatientBox;
