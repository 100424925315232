import * as React from 'react';

import './ContentPanel.scss';
import LandingPage from '../Authentication/landingPage/LandingPage';
import { GlobalUserContext } from '../../shared/contexts/GlobalUserContext';
import { Section } from '../../shared/types/types';

export interface ContentPanelProps {
  section: Section;
}

export const ContentPanel = ({ section }: ContentPanelProps) => {
  const { token } = React.useContext(GlobalUserContext);
  return (
    <div className="content-panel-container">
      {token ? <LandingPage section={section} /> : null}
    </div>
  );
};
