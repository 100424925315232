import './GridContainer.scss';

import { GridContainerProps } from '../../shared/types/types';
export const GridContainer = ({
  children,
  column,
  columnSize,
  row,
  rowSize,
  customColumnTemplate,
  customRowTemplate,
  direction,
}: GridContainerProps) => {
  const colSize = !columnSize ? 1 : columnSize;
  const rSize = !rowSize ? 0 : rowSize;
  const gridTemplateColumns = !customColumnTemplate
    ? `repeat(${column}, ${colSize}fr)`
    : customColumnTemplate;
  const gridTemplateRows = !customRowTemplate
    ? `repeat(${row}, ${rSize}fr`
    : customRowTemplate;
  const gridAutoFlow = !direction ? 'column' : direction;

  return (
    <div
      className="grid-container"
      style={{
        gridTemplateColumns,
        gridTemplateRows,
        gridAutoFlow,
      }}
    >
      {children}
    </div>
  );
};
