import { IconTypeProps } from '../shared/types/types';

export function SearchOutlined({
  width = '24',
  height = '24',
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.7195 22.3067L17.7554 16.3426C19.3807 14.3548 20.1798 11.8184 19.9874 9.25796C19.7951 6.69754 18.6259 4.309 16.7219 2.5864C14.8178 0.863809 12.3245 -0.0610491 9.75763 0.00313002C7.19079 0.0673091 4.74681 1.11562 2.93121 2.93121C1.11562 4.74681 0.0673091 7.19079 0.00313002 9.75763C-0.0610491 12.3245 0.863809 14.8178 2.5864 16.7219C4.309 18.6259 6.69754 19.7951 9.25796 19.9874C11.8184 20.1798 14.3548 19.3807 16.3426 17.7554L22.3067 23.7195C22.4951 23.9015 22.7475 24.0022 23.0095 24C23.2715 23.9977 23.5221 23.8926 23.7073 23.7073C23.8926 23.5221 23.9977 23.2715 24 23.0095C24.0022 22.7475 23.9015 22.4951 23.7195 22.3067ZM10.0238 18.0172C8.44282 18.0172 6.89737 17.5484 5.58286 16.6701C4.26835 15.7917 3.24381 14.5433 2.6388 13.0827C2.0338 11.6221 1.8755 10.0149 2.18393 8.46433C2.49236 6.91376 3.25366 5.48946 4.37156 4.37156C5.48946 3.25366 6.91376 2.49236 8.46433 2.18393C10.0149 1.8755 11.6221 2.0338 13.0827 2.6388C14.5433 3.24381 15.7917 4.26835 16.6701 5.58286C17.5484 6.89737 18.0172 8.44282 18.0172 10.0238C18.0148 12.143 17.1719 14.1748 15.6734 15.6734C14.1748 17.1719 12.143 18.0148 10.0238 18.0172Z"
        fill={color}
      />
    </svg>
  );
}
