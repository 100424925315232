import { IconButtonProps } from ".";
import { FilterOutlined } from "../../../../icons/FilterOutlined";
import "./IconButton.scss";

export function FilterButton({onClick, width, height, color}:IconButtonProps){
    return(
        <div className={'icon-button'} onClick={onClick}>
            <FilterOutlined width={width} height={height} color={color}/>
        </div>
    )
}