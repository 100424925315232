import React from "react";

export const Logout = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="17"
      viewBox="0 0 24 17"
      fill={props.fill}
      version="1.1"
      id="svg296"
    >
      <g
        style={{ fill: props.fill }}
        id="g389"
        transform="translate(3.6433101,2.92401)"
      >
        <path
          d="M 12.151603,3.2502632 9.5655834,0.66425722 c -0.12574,-0.121439 -0.29414,-0.188636 -0.46894,-0.187117 -0.17479,0.001519 -0.342,0.071632 -0.46561,0.195238 -0.1236,0.123605 -0.19372,0.290813 -0.19523,0.46561498 -0.00152,0.17479 0.06567,0.3432 0.18711,0.46893 l 2.5859896,2.586 c 0.0768,0.07836 0.144,0.16565 0.2,0.26 -0.01,0 -0.018,-0.00533 -0.028,-0.00533 l -10.45598361,0.02133 c -0.176811,0 -0.34638,0.07024 -0.471404,0.19526 -0.125024,0.12503 -0.195262,0.2946 -0.195262,0.47141 0,0.17681 0.070238,0.34638 0.195262,0.4714 0.125024,0.12503 0.294593,0.19527 0.471404,0.19527 L 11.376903,5.7809232 c 0.0187,0 0.034,-0.00933 0.052,-0.01066 -0.0591,0.11277 -0.1341,0.21649 -0.2227,0.308 l -2.5859596,2.586 c -0.06367,0.0615 -0.11446,0.13506 -0.1494,0.2164 -0.03493,0.08133 -0.05333,0.16881 -0.0541,0.25733 -7.6e-4,0.08852 0.0161,0.17631 0.04962,0.25824 0.03352,0.08193 0.08303,0.15636 0.14562,0.21896 0.0626,0.06259 0.13703,0.1121 0.21896,0.14562 0.08193,0.03352 0.16972,0.05039 0.25824,0.04962 0.08852,-7.7e-4 0.176,-0.01916 0.25733,-0.0541 0.08134,-0.03494 0.1549,-0.08573 0.2164,-0.1494 l 2.5859896,-2.58601 c 0.4999,-0.50007 0.7808,-1.17823 0.7808,-1.88533 0,-0.7071 -0.2809,-1.38526 -0.7808,-1.88533 z"
          fill={props.fill}
          id="path380"
        />
        <path
          d="M 4.66667,14.6667 H 3.33333 C 2.8029,14.6667 2.29419,14.456 1.91912,14.0809 1.54405,13.7058 1.33333,13.1971 1.33333,12.6667 V 3.33333 C 1.33333,2.8029 1.54405,2.29419 1.91912,1.91912 2.29419,1.54405 2.8029,1.33333 3.33333,1.33333 h 1.33334 c 0.17681,0 0.34638,-0.07023 0.4714,-0.19526 C 5.2631,1.01305 5.33333,0.843478 5.33333,0.666667 5.33333,0.489856 5.2631,0.320286 5.13807,0.195262 5.01305,0.0702379 4.84348,0 4.66667,0 H 3.33333 C 2.4496,0.00105857 1.60237,0.352588 0.97748,0.97748 0.352588,1.60237 0.00105857,2.4496 0,3.33333 v 9.33337 c 0.00105857,0.8837 0.352588,1.7309 0.97748,2.3558 C 1.60237,15.6474 2.4496,15.9989 3.33333,16 h 1.33334 c 0.17681,0 0.34638,-0.0702 0.4714,-0.1953 0.12503,-0.125 0.19526,-0.2946 0.19526,-0.4714 0,-0.1768 -0.07023,-0.3463 -0.19526,-0.4714 -0.12502,-0.125 -0.29459,-0.1952 -0.4714,-0.1952 z"
          fill={props.fill}
          id="path294"
          transform="translate(-3.6433101,-2.92401)"
        />
      </g>
    </svg>
  );
};
