import { v4 as uuidv4 } from 'uuid';
import { CAS_FRONT_END_API_URL, TEMP_AUTH } from '../constants';

export interface Auth {
  token: string | null;
}

export interface TAELSAuthHeader {
  Authorization: string;
  'X-Correlation-Id': string;
  'X-TAELS-Authorization': string;
}

export type AuthHeader = TAELSAuthHeader | {};



const getAuthHeader = (fetchURL: string): AuthHeader => {
  // returns a TAELS auth header if user is logged in and request is to the api url
  if (!fetchURL) return {};

  const token = TEMP_AUTH; // TODO this needs to point to a window sessionStorage key for 'user_auth' when login is possible
  const isLoggedIn = !!token;
  const isApiUrl = fetchURL.startsWith(CAS_FRONT_END_API_URL);

  if (isLoggedIn && isApiUrl) {
    return {
      headers: {
        Authorization: `Bearer ${token}`, // TODO add Okta token when login is possible
        'X-Correlation-Id': uuidv4(), // random token for tracing purposes
        'X-TAELS-Authorization': 'WAITING ON TAE JWT', // TODO add TAELS JWT when possible
      },
    };
  } else {
    // TODO Authentication failed, send back to login screen.
    return {};
  }
};

interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

export async function fetchAuthenticated<T> (
  fetchURL: string,
  config: RequestInit
):Promise<HttpResponse<T>> {
  // append authentication to config headers
  const authenticatedConfig = {
    ...config,
    ...getAuthHeader(fetchURL),
  };
  const response: HttpResponse<T> = await fetch(fetchURL, authenticatedConfig);
  response.parsedBody = await response.json();
  return response;
};
