import { Button } from '../../../../../components/Button/Button';
import { LoadingSpinner } from '../../../../../components/common/loader/LoadingSpinner';
import { Trash } from '../../../../../icons/Trash';
import './InfusionNoteDeleteVerify.scss';

export interface InfusionNoteDeleteVerifyProps {
  onCancelClick: () => void;
  onOkClick: () => void;
  isDeleting: boolean;
}

export function InfusionNoteDeleteVerify({
  onCancelClick,
  onOkClick,
  isDeleting,
}: InfusionNoteDeleteVerifyProps) {
  const handleCancelClick = () => {
    onCancelClick();
  };

  const handleOkClick = () => {
    onOkClick();
  };

  return (
    <div className="infusion-note-delete-verify-container">
      <div className="infusion-note-delete-verify-title">
        Delete this infusion note?
      </div>
      <div className="infusion-note-delete-verify-controls">
        <Button
          className="ok-button"
          label={isDeleting ? '' : 'Delete'}
          onClick={handleOkClick}
          icon={isDeleting ? <LoadingSpinner /> : <Trash />}
          iconPlacement={'left'}
        />
        <Button
          className="cancel-button"
          label="Cancel"
          onClick={handleCancelClick}
        />
      </div>
    </div>
  );
}
