import { IconTypeProps } from '../shared/types/types';
import '../shared/styles/variables.scss';

export function AngleLeft({
  width = 24,
  height = 24,
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6 12.71C10.5063 12.617 10.4319 12.5064 10.3811 12.3846C10.3303 12.2627 10.3042 12.132 10.3042 12C10.3042 11.868 10.3303 11.7373 10.3811 11.6154C10.4319 11.4936 10.5063 11.383 10.6 11.29L15.19 6.71C15.2837 6.61704 15.3581 6.50644 15.4089 6.38458C15.4597 6.26272 15.4858 6.13201 15.4858 6C15.4858 5.86799 15.4597 5.73728 15.4089 5.61542C15.3581 5.49356 15.2837 5.38296 15.19 5.29C15.0026 5.10375 14.7492 4.99921 14.485 4.99921C14.2208 4.99921 13.9674 5.10375 13.78 5.29L9.19001 9.88C8.62821 10.4425 8.31265 11.205 8.31265 12C8.31265 12.795 8.62821 13.5575 9.19001 14.12L13.78 18.71C13.9663 18.8948 14.2177 18.9989 14.48 19C14.6116 19.0008 14.7421 18.9755 14.8639 18.9258C14.9857 18.876 15.0966 18.8027 15.19 18.71C15.2837 18.617 15.3581 18.5064 15.4089 18.3846C15.4597 18.2627 15.4858 18.132 15.4858 18C15.4858 17.868 15.4597 17.7373 15.4089 17.6154C15.3581 17.4936 15.2837 17.383 15.19 17.29L10.6 12.71Z"
        fill={color}
      />
    </svg>
  );
}
