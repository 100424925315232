import encrypt from './encrypt';
import decrypt from './decrypt';
import {
  encryptionInterface,
  ObfuscationCurrentKeyServiceResult,
} from './obfuscationInterfaces';
import { CAS_BACK_END_API_URL } from 'src/constants';
import { fetchAuthenticated } from 'src/controllers';

export interface securityDataInterface {
  obfuscationId?: string | null;
  Securitykey?: string | null;
  initVector?: string | null;
}

let securityInfo: securityDataInterface | null = null;

export async function cipherValues(
  data: any,
  cipher: string,
  securityData: securityDataInterface
) {
  // Please do not delete... will be added in future ticket
  // await this.initDoSkipEncryptionOfPii();
  // if (this.skipPIIObfuscation) return;
  if (typeof data !== 'object') return;
  for (const key in data) {
    if (data[key] === null || data[key] === undefined) continue;

    if (key.indexOf('encrypt_') !== 0) {
      await cipherValues(data[key], cipher, securityData);
    } else {
      const encryptionDetails: encryptionInterface = {
        data:
          cipher === 'encrypt'
            ? JSON.stringify(data[key])
            : data[key],
        initVector:
          cipher === 'encrypt'
            ? securityData.initVector
            : securityData.initVector,
        Securitykey:
          cipher === 'encrypt'
            ? securityData.Securitykey
            : securityData.Securitykey,
      };

      if (cipher === 'encrypt') {
        data[key] = await encrypt(encryptionDetails);
      } else {
        const decryptedValue = await decrypt(encryptionDetails);
        data[key] = JSON.parse(decryptedValue);
      }
    }
  }
  return data;
}

export async function encryptObject(
  dataSetToEncrypt: any,
  token: any,
  securityData: securityDataInterface | null
) {
  if (process.env.TURNOFFENCRYPTION) return dataSetToEncrypt;
  if (!securityData?.Securitykey) {
    securityData = await getCurrentSecurityKeyInfo(token);
  }
  if (Array.isArray(dataSetToEncrypt)) {
    const bindingArray: any = [];
    for (let i = 0; i < dataSetToEncrypt.length; i++) {
      const res = await encryptObject(
        dataSetToEncrypt[i],
        token,
        securityData
      );
      if (i === 0) res['obfuscationId'] = securityData.obfuscationId;
      bindingArray.push(res);
    }
    return bindingArray;
  } else if (typeof dataSetToEncrypt === 'object') {
    await cipherValues(dataSetToEncrypt, 'encrypt', securityData);
    dataSetToEncrypt['obfuscationId'] = securityData.obfuscationId;
    return dataSetToEncrypt;
  }
}

export async function decryptObject(
  dataSetToDecrypt: any,
  token: any,
  securityDataDecrypt: securityDataInterface | null
) {
  if (process.env.TURNOFFENCRYPTION) return dataSetToDecrypt;

  if (typeof dataSetToDecrypt !== 'object') return dataSetToDecrypt;
  let obfuscationId;
  if (!securityDataDecrypt?.Securitykey) {
    if (Array.isArray(dataSetToDecrypt)) {
      securityDataDecrypt = previousSecurityData(
        securityDataDecrypt,
        dataSetToDecrypt[0]['obfuscationId']
      );
      if (!securityDataDecrypt) {
        securityDataDecrypt = await getSecurityKeyInfoById(
          dataSetToDecrypt[0]['obfuscationId'],
          token
        );
      }
      delete dataSetToDecrypt[0]['obfuscationId'];
    } else {
      obfuscationId = dataSetToDecrypt['obfuscationId']
        ? dataSetToDecrypt['obfuscationId']
        : obfuscationId;
      securityDataDecrypt = previousSecurityData(
        securityDataDecrypt,
        obfuscationId
      );
      if (!securityDataDecrypt) {
        securityDataDecrypt = await getSecurityKeyInfoById(
          obfuscationId,
          token
        );
      }
      delete dataSetToDecrypt['obfuscationId'];
    }
  }
  if (Array.isArray(dataSetToDecrypt)) {
    const bindingArray: any = [];
    for (let i = 0; i < dataSetToDecrypt.length; i++) {
      const res = await decryptObject(
        dataSetToDecrypt[i],
        token,
        securityDataDecrypt
      );
      bindingArray.push(res);
    }
    return bindingArray;
  } else if (typeof dataSetToDecrypt === 'object') {
    await cipherValues(
      dataSetToDecrypt,
      'decrypt',
      securityDataDecrypt
    );
    return dataSetToDecrypt;
  }
}

export async function getSecurityKeyInfoById(
  obfuscationId: string | null,
  token: string
) {
  let data = {
    Securitykey: null,
    initVector: null,
    obfuscationId: null,
  };
  const url = `${CAS_BACK_END_API_URL}/encryptionKey/${obfuscationId}`;
  const config: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response =
    await fetchAuthenticated<ObfuscationCurrentKeyServiceResult>(
      url,
      config
    );
  if (response?.ok) {
    const result = response?.parsedBody?.result;
    const data = {
      Securitykey: result?.item?.Securitykey,
      initVector: result?.item?.initVector,
      obfuscationId: result?.item?._id,
    };
    previousSecurityData(data, null);
    return data;
  } else {
    return data;
  }
}

export async function getCurrentSecurityKeyInfo(token: string) {
  let data = {
    Securitykey: null,
    initVector: null,
    obfuscationId: null,
  };

  const url = CAS_BACK_END_API_URL + '/encryptionCurrentKey';
  const config: RequestInit = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response =
    await fetchAuthenticated<ObfuscationCurrentKeyServiceResult>(
      url,
      config
    );
  if (response?.ok) {
    //@ts-ignore
    const result = response?.parsedBody?.result;
    data.Securitykey = result.item[0].Securitykey;
    data.initVector = result.item[0].initVector;
    data.obfuscationId = result.item[0]._id;
    previousSecurityData(data, null);
    return data;
  } else {
    return data;
  }
}

export function previousSecurityData(
  securityData: securityDataInterface | null,
  obfuscationId: string | null
) {
  if (obfuscationId === null) {
    securityInfo = securityData;
    return securityInfo;
  } else if (securityData && obfuscationId) {
    return securityData.obfuscationId === obfuscationId
      ? securityData
      : null;
  } else {
    return null;
  }
}
