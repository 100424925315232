import { IconTypeProps } from '../shared/types/types';

export function AngleUp({
  width = 24,
  height = 24,
  color = '#0091b2',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 15.5C17.8684 15.5007 17.7379 15.4755 17.6161 15.4258C17.4943 15.376 17.3834 15.3027 17.29 15.21L12.71 10.62C12.617 10.5263 12.5064 10.4519 12.3846 10.4011C12.2627 10.3503 12.132 10.3242 12 10.3242C11.868 10.3242 11.7373 10.3503 11.6154 10.4011C11.4936 10.4519 11.383 10.5263 11.29 10.62L6.71 15.21C6.5217 15.3983 6.2663 15.5041 6 15.5041C5.7337 15.5041 5.47831 15.3983 5.29 15.21C5.1017 15.0217 4.99591 14.7663 4.99591 14.5C4.99591 14.3681 5.02188 14.2376 5.07234 14.1157C5.1228 13.9939 5.19676 13.8832 5.29 13.79L9.88 9.21C10.4499 8.66243 11.2096 8.35663 12 8.35663C12.7904 8.35663 13.5501 8.66243 14.12 9.21L18.71 13.79C18.8037 13.883 18.8781 13.9936 18.9289 14.1154C18.9797 14.2373 19.0058 14.368 19.0058 14.5C19.0058 14.632 18.9797 14.7627 18.9289 14.8846C18.8781 15.0064 18.8037 15.117 18.71 15.21C18.6166 15.3027 18.5057 15.376 18.3839 15.4258C18.2621 15.4755 18.1316 15.5007 18 15.5Z"
        fill={color}
      />
    </svg>
  );
}
