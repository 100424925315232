import { IconTypeProps } from '../../shared/types/types';
import '../../shared/styles/variables.scss';

export function Bold({
  width = 16,
  height = 16,
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_639_12059)">
        <path
          d="M11.9693 7.10868C12.4064 6.40257 12.6468 5.59243 12.6655 4.7622C12.6842 3.93197 12.4805 3.11183 12.0757 2.38676C11.6708 1.66169 11.0794 1.05807 10.3628 0.638412C9.64622 0.218759 8.83044 -0.00165324 8 9.33642e-06H3.33333C2.97971 9.33642e-06 2.64057 0.140485 2.39052 0.390534C2.14048 0.640582 2 0.979721 2 1.33334V14.6667C2 15.0203 2.14048 15.3594 2.39052 15.6095C2.64057 15.8595 2.97971 16 3.33333 16H10C11.0623 16.0011 12.0931 15.6395 12.9218 14.9749C13.7506 14.3104 14.3276 13.3828 14.5573 12.3456C14.787 11.3085 14.6557 10.224 14.1851 9.27165C13.7144 8.31931 12.9327 7.55622 11.9693 7.10868ZM4.66667 2.66668H8C8.53043 2.66668 9.03914 2.87739 9.41421 3.25246C9.78928 3.62753 10 4.13624 10 4.66668C10 5.19711 9.78928 5.70582 9.41421 6.08089C9.03914 6.45596 8.53043 6.66668 8 6.66668H4.66667V2.66668ZM10 13.3333H4.66667V9.33334H10C10.5304 9.33334 11.0391 9.54405 11.4142 9.91913C11.7893 10.2942 12 10.8029 12 11.3333C12 11.8638 11.7893 12.3725 11.4142 12.7476C11.0391 13.1226 10.5304 13.3333 10 13.3333Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_639_12059">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
