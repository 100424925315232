import { Route, Routes, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import './App.scss';
import { useContext, useEffect, useState } from 'react';
import { ContentPanel } from './components/ContentPanel/ContentPanel';
import Navbar from './components/Navbar/Navbar';
import Sidebar from './components/Sidebar/Sidebar';
import SubNav from './components/SubNav/SubNav';
import Patient from './pages/patient/Patient';
import IdlePopup from './components/IdlePopup/IdlePopup';
import useIdleTimer from './shared/hooks/useIdleTimer';
import { PrivacyContext } from './shared/contexts/PrivacyContext';
import TemporaryLoginPopup from './components/TemporaryLoginPopup/TemporaryLoginPopup';
import { Section } from './shared/types/types';
import { GlobalUserContext } from './shared/contexts/GlobalUserContext';

const App = () => {
  const { isAuthenticated } = useAuth0();

  const [stateShowIdlePopup, setStateShowIdlePopup] = useState(false);
  const [stateCloseIdlePopup, setStateCloseIdlePopup] =
    useState(false);
  const [stateshowPrivacyOverlay, setStateShowPrivacyOverlay] =
    useState(false);
  const [stateClosePrivacyOverlay, setStateClosePrivacyOverlay] =
    useState(false);

  const [section, setSection] = useState<Section>('records');

  //TODO: Fix to stop the re-render happening from useIdleTimer hook. To be replaced with a permanent fix inside useIdleTimer hook.
  const HandleRerender = ({
    ...props
  }: {
    closeIdlePop: boolean;
    closePrivacyOverlay: boolean;
  }) => {
    const {
      showIdlePopup,
      setShowIdlePopup,
      showPrivacyOverlay,
      setShowPrivacyOverlay,
      resetPrivacyTimer,
      resetIdleTimer,
    } = useIdleTimer();

    const resetIdleHelper = () => {
      setShowIdlePopup(false);
      setStateShowIdlePopup(false);
      resetIdleTimer();
      setStateCloseIdlePopup(false);
    };

    const resetPrivacyHelper = () => {
      setShowPrivacyOverlay(false);
      setStateShowPrivacyOverlay(false);
      resetPrivacyTimer();
      setStateClosePrivacyOverlay(false);
    };

    useEffect(() => {
      showIdlePopup && setStateShowIdlePopup(showIdlePopup);
      stateCloseIdlePopup && resetIdleHelper();
      showPrivacyOverlay &&
        setStateShowPrivacyOverlay(showPrivacyOverlay);
      stateClosePrivacyOverlay && resetPrivacyHelper();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      showIdlePopup,
      stateCloseIdlePopup,
      stateClosePrivacyOverlay,
      showPrivacyOverlay,
    ]);

    return <></>;
  };

  const showLoginPopup = !isAuthenticated ? true : false;

  const handleResetPrivacy = () => {
    setStateClosePrivacyOverlay(true);
  };

  const navigate = useNavigate();

  const handleIdleOnClose = (isIdleClose: boolean) => {
    setStateCloseIdlePopup(isIdleClose);
  };

  const { setPatientInfo } = useContext(GlobalUserContext);

  const handleSidebarSectionClick = (name: Section) => {
    setSection(name);
    setPatientInfo(null);
    navigate('/');
  };

  return (
    <PrivacyContext.Provider
      value={{
        active: stateshowPrivacyOverlay,
        resetPrivacy: handleResetPrivacy,
      }}
    >
      <HandleRerender
        closeIdlePop={stateCloseIdlePopup}
        closePrivacyOverlay={stateClosePrivacyOverlay}
      />
      <div className="App">
        {showLoginPopup && <TemporaryLoginPopup />}
        <div className="app-content">
          <Sidebar
            onSidebarSectionClick={handleSidebarSectionClick}
          />
          <div className="page-content">
            <header>
              <Navbar section={section} />
              <SubNav />
            </header>
            <section className="main-content">
              <Routes>
                <Route
                  path="/"
                  element={<ContentPanel section={section} />}
                />
                <Route
                  path="/patients/:id"
                  element={<Patient section={section} />}
                />
              </Routes>
            </section>
          </div>
          {stateShowIdlePopup && (
            <IdlePopup
              show={stateShowIdlePopup}
              onClose={handleIdleOnClose}
            />
          )}
        </div>
      </div>
    </PrivacyContext.Provider>
  );
};

export default App;
