import React, { ReactNode } from 'react';
import './Cell.scss';

export interface CellProps {
  children?: ReactNode;
}

export function Cell(props: CellProps) {
  return <td className="cell-container">{props.children}</td>;
}
