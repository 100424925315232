import { IconTypeProps } from '../shared/types/types';

export function SortOutlinedAscending({
  width = '24',
  height = '24',
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1155_25159)">
        <path
          d="M18.873 11.021H5.12699C4.71481 11.0207 4.3116 10.9006 3.96644 10.6753C3.62128 10.45 3.34906 10.1292 3.18292 9.75196C3.01677 9.37474 2.96386 8.95736 3.03063 8.55062C3.0974 8.14388 3.28097 7.76531 3.55899 7.46101L10.046 0.87201C10.2984 0.600733 10.6046 0.384989 10.9449 0.238516C11.2853 0.0920428 11.6524 0.0180498 12.023 0.0212481C12.3935 0.0244465 12.7593 0.104766 13.0971 0.257093C13.4349 0.40942 13.7373 0.630416 13.985 0.90601L20.416 7.43401C20.7014 7.73573 20.8922 8.11445 20.9648 8.52339C21.0374 8.93233 20.9886 9.35358 20.8245 9.73511C20.6604 10.1166 20.3881 10.4417 20.0413 10.6703C19.6945 10.8988 19.2883 11.0207 18.873 11.021ZM12 24.011C11.6254 24.0108 11.255 23.9318 10.913 23.779C10.571 23.6261 10.265 23.403 10.015 23.124L3.58399 16.6C3.29844 16.2984 3.10753 15.9197 3.03486 15.5108C2.96219 15.1019 3.01095 14.6806 3.17511 14.2991C3.33927 13.9176 3.61163 13.5925 3.95853 13.3641C4.30543 13.1358 4.71166 13.014 5.12699 13.014H18.873C19.285 13.0142 19.688 13.1342 20.0331 13.3593C20.3781 13.5845 20.6503 13.905 20.8164 14.282C20.9826 14.6591 21.0355 15.0762 20.9689 15.4828C20.9022 15.8894 20.7188 16.2678 20.441 16.572L13.954 23.161C13.7052 23.4306 13.403 23.6455 13.0665 23.7918C12.7301 23.9382 12.3669 24.0128 12 24.011ZM5.12699 15.011C5.10284 15.0111 5.07923 15.0182 5.05902 15.0315C5.03882 15.0447 5.02289 15.0635 5.01315 15.0856C5.00342 15.1078 5.0003 15.1322 5.00418 15.1561C5.00806 15.1799 5.01876 15.2021 5.03499 15.22L11.472 21.754C11.5361 21.8325 11.6166 21.8962 11.7077 21.9405C11.7989 21.9849 11.8986 22.009 12 22.011C12.0944 22.0114 12.1878 21.9918 12.274 21.9533C12.3602 21.9149 12.4373 21.8585 12.5 21.788L18.993 15.188C19.0021 15.1715 19.0069 15.1529 19.0069 15.134C19.0069 15.1151 19.0021 15.0965 18.993 15.08C18.9849 15.0568 18.9693 15.0369 18.9486 15.0235C18.928 15.0101 18.9035 15.004 18.879 15.006L5.12699 15.011Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1155_25159">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
