import React from 'react';

export const CarrotLeft = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="8"
      height="15"
      viewBox="0 0 8 15"
      fill="none"
      {...props}
    >
      <path
        d="M2.59999 8.39966C2.50626 8.30669 2.43187 8.19609 2.3811 8.07423C2.33033 7.95237 2.3042 7.82167 2.3042 7.68965C2.3042 7.55764 2.33033 7.42694 2.3811 7.30508C2.43187 7.18322 2.50626 7.07262 2.59999 6.97965L7.18998 2.39965C7.28371 2.30669 7.35811 2.19609 7.40887 2.07423C7.45964 1.95237 7.48578 1.82166 7.48578 1.68965C7.48578 1.55764 7.45964 1.42693 7.40887 1.30507C7.35811 1.18321 7.28371 1.07261 7.18998 0.979651C7.00262 0.7934 6.74917 0.688858 6.48498 0.688858C6.2208 0.688858 5.96735 0.7934 5.77999 0.979651L1.18999 5.56965C0.628194 6.13216 0.312637 6.89465 0.312637 7.68965C0.312637 8.48466 0.628194 9.24715 1.18999 9.80966L5.77999 14.3997C5.96625 14.5844 6.21765 14.6886 6.47998 14.6897C6.61159 14.6904 6.74205 14.6652 6.86389 14.6154C6.98573 14.5657 7.09654 14.4923 7.18998 14.3997C7.28371 14.3067 7.35811 14.1961 7.40887 14.0742C7.45964 13.9524 7.48578 13.8217 7.48578 13.6897C7.48578 13.5576 7.45964 13.4269 7.40887 13.3051C7.35811 13.1832 7.28371 13.0726 7.18998 12.9797L2.59999 8.39966Z"
        fill="#FBFBFB"
      />
    </svg>
  );
};
