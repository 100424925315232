import './HorizontalRule.scss';

export interface HorizontalRuleProps {
  dashed: boolean;
}

export function HorizontalRule({ dashed }: HorizontalRuleProps) {
  return (
    <div
      className={`horizontal-rule-container ${
        dashed ? 'dashed' : ''
      }`}
    />
  );
}
