import { AngleDown } from '../../icons/AngleDown';
import { AngleUp } from '../../icons/AngleUp';
import './ToggleExpand.scss';

export interface ToggleExpandProps {
  open: boolean;
  onClick: () => void;
}

export function ToggleExpand({ open, onClick }: ToggleExpandProps) {
  return (
    <div onClick={onClick} className={'buttons'}>
      {open ? <AngleDown /> : <AngleUp />}
    </div>
  );
}
