import { IconTypeProps } from '../../shared/types/types';
import '../../shared/styles/variables.scss';

export function AlignRight({
  width = 16,
  height = 16,
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_639_12078)">
        <path
          d="M0.666666 4.00002H15.3333C15.5101 4.00002 15.6797 3.92978 15.8047 3.80476C15.9298 3.67973 16 3.51016 16 3.33335C16 3.15654 15.9298 2.98697 15.8047 2.86195C15.6797 2.73692 15.5101 2.66669 15.3333 2.66669H0.666666C0.489855 2.66669 0.320286 2.73692 0.195262 2.86195C0.0702379 2.98697 0 3.15654 0 3.33335C0 3.51016 0.0702379 3.67973 0.195262 3.80476C0.320286 3.92978 0.489855 4.00002 0.666666 4.00002Z"
          fill={color}
        />
        <path
          d="M15.3333 6H5.99998C5.82317 6 5.6536 6.07024 5.52858 6.19526C5.40355 6.32029 5.33331 6.48985 5.33331 6.66667C5.33331 6.84348 5.40355 7.01304 5.52858 7.13807C5.6536 7.26309 5.82317 7.33333 5.99998 7.33333H15.3333C15.5101 7.33333 15.6797 7.26309 15.8047 7.13807C15.9297 7.01304 16 6.84348 16 6.66667C16 6.48985 15.9297 6.32029 15.8047 6.19526C15.6797 6.07024 15.5101 6 15.3333 6Z"
          fill={color}
        />
        <path
          d="M15.3333 12.6667H5.99998C5.82317 12.6667 5.6536 12.7369 5.52858 12.8619C5.40355 12.987 5.33331 13.1565 5.33331 13.3334C5.33331 13.5102 5.40355 13.6797 5.52858 13.8048C5.6536 13.9298 5.82317 14 5.99998 14H15.3333C15.5101 14 15.6797 13.9298 15.8047 13.8048C15.9297 13.6797 16 13.5102 16 13.3334C16 13.1565 15.9297 12.987 15.8047 12.8619C15.6797 12.7369 15.5101 12.6667 15.3333 12.6667Z"
          fill={color}
        />
        <path
          d="M15.3333 9.33331H0.666666C0.489855 9.33331 0.320286 9.40355 0.195262 9.52858C0.0702379 9.6536 0 9.82317 0 9.99998C0 10.1768 0.0702379 10.3464 0.195262 10.4714C0.320286 10.5964 0.489855 10.6667 0.666666 10.6667H15.3333C15.5101 10.6667 15.6797 10.5964 15.8047 10.4714C15.9298 10.3464 16 10.1768 16 9.99998C16 9.82317 15.9298 9.6536 15.8047 9.52858C15.6797 9.40355 15.5101 9.33331 15.3333 9.33331Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_639_12078">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
