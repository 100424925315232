import { IconTypeProps } from '../shared/types/types';

export function Edit({
  width = 24,
  height = 24,
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.853 1.14795C22.1732 0.469151 21.2517 0.0878906 20.291 0.0878906C19.3303 0.0878906 18.4089 0.469151 17.729 1.14795L1.46502 17.412C0.999267 17.8751 0.629977 18.426 0.378513 19.0327C0.127049 19.6395 -0.00159798 20.2901 1.49812e-05 20.947L1.49812e-05 23C1.49812e-05 23.2652 0.105372 23.5195 0.292908 23.7071C0.480445 23.8946 0.734799 24 1.00002 24H3.05302C3.70978 24.0018 4.36039 23.8734 4.96717 23.6221C5.57396 23.3708 6.12487 23.0016 6.58802 22.536L22.853 6.27095C23.5315 5.59115 23.9126 4.66992 23.9126 3.70945C23.9126 2.74899 23.5315 1.82776 22.853 1.14795ZM5.17402 21.122C4.61002 21.6822 3.84798 21.9977 3.05302 22H2.00002V20.947C1.999 20.5529 2.07617 20.1625 2.22705 19.7984C2.37793 19.4343 2.59953 19.1038 2.87902 18.826L15.222 6.48295L17.522 8.78296L5.17402 21.122ZM21.438 4.85695L18.932 7.36395L16.632 5.06895L19.139 2.56195C19.29 2.41126 19.4693 2.29179 19.6665 2.21036C19.8636 2.12893 20.0749 2.08714 20.2883 2.08738C20.5016 2.08761 20.7128 2.12986 20.9099 2.21172C21.1069 2.29357 21.2858 2.41343 21.4365 2.56445C21.5872 2.71547 21.7067 2.8947 21.7881 3.09189C21.8695 3.28908 21.9113 3.50038 21.9111 3.71372C21.9109 3.92706 21.8686 4.13827 21.7868 4.33529C21.7049 4.5323 21.585 4.71126 21.434 4.86195L21.438 4.85695Z"
        fill={color}
      />
    </svg>
  );
}
