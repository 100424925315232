import { Button } from '../../../../components/Button/Button';
import { Cell } from '../../../../components/common/table/Cell/Cell';
import { Row } from '../../../../components/common/table/Row/Row';
import { Check } from '../../../../shared/assets/check';
import { Pencil } from '../../../../shared/assets/pencil';
import { XClose } from '../../../../shared/assets/x-close';
import clsx from 'clsx';
import { InfusionSession } from '../PatientInfusion';
import { format, intervalToDuration, isValid } from 'date-fns';
import { useState } from 'react';
import './InfusionHistoryRow.scss';

export interface InfusionHistoryRowProps {
  session: InfusionSession;
  index: number;
}

export const InfusionHistoryRow = ({
  session,
  index,
}: InfusionHistoryRowProps) => {
  const [edit, setEdit] = useState(false);
  const getDuration = (
    startDateTime: string,
    endDateTime: string
  ) => {
    const duration = intervalToDuration({
      start: new Date(startDateTime),
      end: new Date(endDateTime),
    });
    let res = '';
    if (duration.hours)
      res += `${duration.hours} ${
        duration.hours === 1 ? 'Hour' : 'Hours'
      }${(duration.minutes || duration.seconds) && ', '} `;
    if (duration.minutes)
      res += `${duration.minutes} ${
        duration.minutes === 1 ? 'Minute' : 'Minutes'
      } `;
    if (duration.seconds && !duration.minutes)
      res += `${duration.seconds} ${
        duration.seconds === 1 ? 'Second' : 'Seconds'
      }`;
    return res;
  };

  return (
    <Row
      index={index}
      key={session._id}
      classes={'infusion-history-row-container'}
    >
      <Cell>
        {isValid(new Date(session.createdDateTime)) &&
          format(new Date(session.createdDateTime), 'MM/dd/yyyy')}
      </Cell>
      <Cell>
        {isValid(new Date(session.encrypt_infusionStartDateTime)) &&
          format(
            new Date(session.encrypt_infusionStartDateTime),
            'HH:mm:ss'
          )}
      </Cell>
      <Cell>
        {session.encrypt_infusionEndDateTime &&
          isValid(new Date(session.encrypt_infusionEndDateTime)) &&
          format(
            new Date(session.encrypt_infusionEndDateTime),
            'HH:mm:ss'
          )}
      </Cell>
      <Cell>
        {session.encrypt_infusionEndDateTime &&
          isValid(new Date(session.encrypt_infusionEndDateTime)) &&
          isValid(new Date(session.encrypt_infusionStartDateTime)) &&
          new Date(session.encrypt_infusionStartDateTime) <
            new Date(session.encrypt_infusionEndDateTime) &&
          getDuration(
            session.encrypt_infusionStartDateTime,
            session.encrypt_infusionEndDateTime
          )}
      </Cell>
      <Cell>{session?.user?.name}</Cell>
      <Cell>
        <div className="history-actions-container">
          {edit && (
            <>
              <Button
                icon={<XClose fill="#2CC0E8" />}
                onClick={() => setEdit(false)}
                width={'40px'}
                height={'40px'}
                className={clsx(
                  'square-icon-button',
                  'cancel-button'
                )}
              />
              <Button
                icon={<Check fill="#FBFBFB" />}
                onClick={() => setEdit(false)}
                width={'40px'}
                height={'40px'}
                className={clsx('square-icon-button', 'save-button')}
              />
            </>
          )}
          {!edit && (
            <Button
              icon={<Pencil fill="#2CC0E8" />}
              onClick={() => setEdit(true)}
              width={'40px'}
              height={'40px'}
              className={clsx(
                'square-icon-button',
                'edit-button-enabled'
              )}
            ></Button>
          )}
        </div>
      </Cell>
    </Row>
  );
};
