import React from 'react';
import { ReactNode } from 'react';
import './HeaderCell.scss';

export interface HeaderCellProps {
  children: ReactNode | [ReactNode];
  align?: string;
}

export function HeaderCell({
  children,
  align = '',
}: HeaderCellProps) {
  return (
    <td className={`header-cell-container`}>
      <div className={`header-cell-content ${align}`}>{children}</div>
    </td>
  );
}
