import { RecordsOutlined } from '../../icons/RecordsOutlined';
import { CarrotDown } from '../../shared/assets/carrot-down';
import { primaryBlue } from '../../shared/styles/variables';
import './Navbar.scss';

export const RecordsButton = () => {
  return (
    <div className="navbar-item nav-dropdown">
      <RecordsOutlined color={primaryBlue} />
      <span className="infusion-dropdown">Records</span>
      <CarrotDown />
    </div>
  );
};
