import { useNavigate, useParams } from 'react-router-dom';
import PatientBox from './PatientBox';
import { PatientInfusion } from './PatientInfusion/PatientInfusion';
import { Button } from '../../components/Button/Button';
import { AngleLeft } from '../../icons/AngleLeft';
import clsx from 'clsx';
import './Patient.scss';
import { Section } from '../../shared/types/types';
import { TreatmentPlans } from './treatmentPlans/TreatmentPlans';
import { testTreatmentPlanList } from './treatmentPlans/testData';
export interface PatientProps {
  section: Section;
}

const Patient = ({ section }: PatientProps) => {
  const { id } = useParams();
  const patientId = typeof id === 'string' ? id : '';
  const navigate = useNavigate();
  return (
    <div className="patient-container">
      <PatientBox patientId={patientId} />
      <PatientInfusion patientId={patientId} section={section} />
      <TreatmentPlans
        patientId={patientId}
        data={testTreatmentPlanList}
      />
      <div className="patient-controls">
        <Button
          label={'Back'}
          className={clsx('go-back-button')}
          onClick={() => {
            navigate('/');
          }}
          icon={<AngleLeft />}
          iconPlacement={'left'}
        />
      </div>
    </div>
  );
};

export default Patient;
