import { IconTypeProps } from '../../shared/types/types';
import '../../shared/styles/variables.scss';

export function Underline({
  width = 16,
  height = 16,
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_639_12063)">
        <path
          d="M8.00002 13.3333C9.41401 13.3317 10.7696 12.7693 11.7695 11.7695C12.7693 10.7696 13.3318 9.414 13.3333 8V0.666666C13.3333 0.489855 13.2631 0.320286 13.1381 0.195262C13.0131 0.0702379 12.8435 0 12.6667 0C12.4899 0 12.3203 0.0702379 12.1953 0.195262C12.0702 0.320286 12 0.489855 12 0.666666V8C12 9.06086 11.5786 10.0783 10.8284 10.8284C10.0783 11.5786 9.06088 12 8.00002 12C6.93915 12 5.92174 11.5786 5.17159 10.8284C4.42145 10.0783 4.00002 9.06086 4.00002 8V0.666666C4.00002 0.489855 3.92978 0.320286 3.80476 0.195262C3.67973 0.0702379 3.51016 0 3.33335 0C3.15654 0 2.98697 0.0702379 2.86195 0.195262C2.73692 0.320286 2.66669 0.489855 2.66669 0.666666V8C2.66828 9.414 3.23069 10.7696 4.23054 11.7695C5.23039 12.7693 6.58602 13.3317 8.00002 13.3333Z"
          fill={color}
        />
        <path
          d="M15.3333 14.6667H0.666666C0.489855 14.6667 0.320286 14.7369 0.195262 14.8619C0.0702379 14.987 0 15.1565 0 15.3334C0 15.5102 0.0702379 15.6797 0.195262 15.8048C0.320286 15.9298 0.489855 16 0.666666 16H15.3333C15.5101 16 15.6797 15.9298 15.8047 15.8048C15.9298 15.6797 16 15.5102 16 15.3334C16 15.1565 15.9298 14.987 15.8047 14.8619C15.6797 14.7369 15.5101 14.6667 15.3333 14.6667Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_639_12063">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
