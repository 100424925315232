import { createContext } from "react";

export interface PrivacyContextProps {
    active: boolean;
    resetPrivacy: () => void;
}

export const PrivacyContext = createContext <PrivacyContextProps> (
    {
        active: false, 
        resetPrivacy: () => {}
    }
)