import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PatientList } from './PatientList/PatientList';
import { Section } from '../../../shared/types/types';
import './LandingPage.scss';

interface UserInfo {
  nickname?: String;
  name?: String;
  picture?: String;
  updated_at?: String;
  sub?: String;
}

export interface LandingPageProps {
  section: Section;
}

const LandingPage = ({ section }: LandingPageProps) => {
  const { user, isAuthenticated } = useAuth0();
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  const auth0Debugging = false;

  useEffect(() => {
    if (!isAuthenticated || !user) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      setUserInfo(user);
      // auth0Debugging && testLambdaAuthorizer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]); // Update if authState changes */

  if (!isAuthenticated || !user) {
    return (
      <div>
        <p>Fetching User Information...</p>
      </div>
    );
  }

  const auth0DebuggingInfo = user && <div>Hello {user.name}</div>;
  return (
    <div className="landing-page-container">
      {auth0Debugging && auth0DebuggingInfo}
      {userInfo && <PatientList section={section} />}
    </div>
  );
};

export default LandingPage;
