import { IconTypeProps } from '../shared/types/types';
import '../shared/styles/variables.scss';

export function Records({
  width = 24,
  height = 24,
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_522_1045)">
        <path
          d="M7.5 13C6.60998 13 5.73995 12.7361 4.99993 12.2416C4.25991 11.7471 3.68314 11.0443 3.34254 10.2221C3.00195 9.39981 2.91283 8.49501 3.08647 7.6221C3.2601 6.74918 3.68868 5.94736 4.31802 5.31802C4.94736 4.68868 5.74918 4.2601 6.62209 4.08647C7.49501 3.91283 8.39981 4.00195 9.22208 4.34254C10.0443 4.68314 10.7471 5.25991 11.2416 5.99994C11.7361 6.73996 12 7.60999 12 8.5C11.9987 9.69307 11.5241 10.8369 10.6805 11.6805C9.83689 12.5241 8.69307 12.9987 7.5 13ZM14 24H1C0.734784 24 0.48043 23.8946 0.292893 23.7071C0.105357 23.5196 0 23.2652 0 23V22.5C0 20.5109 0.790176 18.6032 2.1967 17.1967C3.60322 15.7902 5.51088 15 7.5 15C9.48912 15 11.3968 15.7902 12.8033 17.1967C14.2098 18.6032 15 20.5109 15 22.5V23C15 23.2652 14.8946 23.5196 14.7071 23.7071C14.5196 23.8946 14.2652 24 14 24ZM17.5 9C16.61 9 15.74 8.73608 14.9999 8.24162C14.2599 7.74715 13.6831 7.04434 13.3425 6.22208C13.0019 5.39981 12.9128 4.49501 13.0865 3.6221C13.2601 2.74918 13.6887 1.94736 14.318 1.31802C14.9474 0.688685 15.7492 0.260102 16.6221 0.0864683C17.495 -0.0871652 18.3998 0.00194979 19.2221 0.342544C20.0443 0.683138 20.7471 1.25991 21.2416 1.99994C21.7361 2.73996 22 3.60999 22 4.5C21.9987 5.69307 21.5241 6.83689 20.6805 7.68052C19.8369 8.52415 18.6931 8.99868 17.5 9ZM16.079 11.021C15.1476 11.146 14.2521 11.4619 13.4485 11.9491C12.6449 12.4362 11.9506 13.084 11.409 13.852C13.6499 14.8697 15.4106 16.7142 16.323 19H23C23.2652 19 23.5196 18.8946 23.7071 18.7071C23.8946 18.5196 24 18.2652 24 18V17.962C23.999 16.9652 23.7853 15.9801 23.3733 15.0725C22.9612 14.1648 22.3603 13.3555 21.6106 12.6986C20.8609 12.0416 19.9797 11.5522 19.0258 11.2629C18.0719 10.9736 17.0673 10.8911 16.079 11.021Z"
          fill={color}
        />
      </g>
      <clipPath id="clip0_522_1045">
        <rect width={width} height={height} fill={color} />
      </clipPath>
    </svg>
  );
}
