import { IconTypeProps } from '../shared/types/types';
import '../shared/styles/variables.scss';

export function AngleDown({
  width = 24,
  height = 24,
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.71 8.20998C18.617 8.11625 18.5064 8.04186 18.3846 7.99109C18.2627 7.94032 18.132 7.91418 18 7.91418C17.868 7.91418 17.7373 7.94032 17.6154 7.99109C17.4936 8.04186 17.383 8.11625 17.29 8.20998L12.71 12.79C12.617 12.8837 12.5064 12.9581 12.3846 13.0089C12.2627 13.0596 12.132 13.0858 12 13.0858C11.868 13.0858 11.7373 13.0596 11.6154 13.0089C11.4936 12.9581 11.383 12.8837 11.29 12.79L6.71 8.20998C6.61704 8.11625 6.50644 8.04186 6.38458 7.99109C6.26272 7.94032 6.13201 7.91418 6 7.91418C5.86799 7.91418 5.73728 7.94032 5.61542 7.99109C5.49356 8.04186 5.38296 8.11625 5.29 8.20998C5.10375 8.39734 4.99921 8.6508 4.99921 8.91498C4.99921 9.17917 5.10375 9.43262 5.29 9.61998L9.88 14.21C10.4425 14.7718 11.205 15.0873 12 15.0873C12.795 15.0873 13.5575 14.7718 14.12 14.21L18.71 9.61998C18.8963 9.43262 19.0008 9.17917 19.0008 8.91498C19.0008 8.6508 18.8963 8.39734 18.71 8.20998Z"
        color={color}
      />
    </svg>
  );
}
