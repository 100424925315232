import { IconTypeProps } from '../../shared/types/types';
import '../../shared/styles/variables.scss';

export function Italics({
  width = 16,
  height = 16,
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_639_12061)">
        <path
          d="M13.3333 0H4.66667C4.48985 0 4.32029 0.0702379 4.19526 0.195262C4.07024 0.320286 4 0.489855 4 0.666666C4 0.843477 4.07024 1.01305 4.19526 1.13807C4.32029 1.2631 4.48985 1.33333 4.66667 1.33333H8.236L6.418 14.6667H2.66667C2.48986 14.6667 2.32029 14.7369 2.19526 14.8619C2.07024 14.9869 2 15.1565 2 15.3333C2 15.5101 2.07024 15.6797 2.19526 15.8047C2.32029 15.9298 2.48986 16 2.66667 16H11.3333C11.5101 16 11.6797 15.9298 11.8047 15.8047C11.9298 15.6797 12 15.5101 12 15.3333C12 15.1565 11.9298 14.9869 11.8047 14.8619C11.6797 14.7369 11.5101 14.6667 11.3333 14.6667H7.764L9.582 1.33333H13.3333C13.5101 1.33333 13.6797 1.2631 13.8047 1.13807C13.9298 1.01305 14 0.843477 14 0.666666C14 0.489855 13.9298 0.320286 13.8047 0.195262C13.6797 0.0702379 13.5101 0 13.3333 0V0Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_639_12061">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
