import React, { useContext, useState } from 'react';
import { CarrotLeft } from '../../shared/assets/carrot-left';
import { SyringeIcon } from '../../shared/assets/syringe-white';
import { TAELogo } from '../../shared/assets/tae-logo-white';
import { GlobalUserContext } from '../../shared/contexts/GlobalUserContext';
import { Button } from '../Button/Button';
import OktaLogin from '../Authentication/Authentication';
import './Sidebar.scss';
import { Records } from '../../icons/Records';
import { white } from '../../shared/styles/variables';
import { Section } from '../../shared/types/types';

export interface SidebarProps {
  label?: string;
  onSidebarSectionClick: (sectionName: Section) => void;
}
export interface loggedInUser {
  firstName?: string;
  lastName?: string;
}

const Sidebar = ({ onSidebarSectionClick }: SidebarProps) => {
  const [sidebarDisplay, setSidebarDisplay] = useState('');
  const globalUserState = useContext(GlobalUserContext);

  const toggleSidebar = (sidebarDisplay: string): void => {
    if (sidebarDisplay === '') setSidebarDisplay('collapsed');
    else if (sidebarDisplay === 'collapsed') setSidebarDisplay('');
  };

  const handleRecordsButtonClick = () => {
    onSidebarSectionClick('records');
  };

  const handleInfusionsButtonClick = () => {
    onSidebarSectionClick('infusion');
  };

  return (
    <div
      data-testid="sidebar-container"
      className={
        sidebarDisplay === ''
          ? 'sidebar-container'
          : 'sidebar-container-collapsed'
      }
      data-cy="sidebar"
    >
      <div className="sidebar-content">
        <div className="sidebar-header">
          <span className="tae-logo">
            <TAELogo />
            <span
              onClick={() => toggleSidebar(sidebarDisplay)}
              className="carrot"
            >
              <CarrotLeft />
            </span>
          </span>

          {sidebarDisplay === '' ? (
            <>
              <Button
                label="Records"
                className="sidebar-button"
                icon={<Records color={white} />}
                iconPlacement="left"
                onClick={handleRecordsButtonClick}
                data-cy="records-button"
              />
              <Button
                label="Infusion"
                className="sidebar-button"
                icon={<SyringeIcon fill={white} />}
                iconPlacement="left"
                onClick={handleInfusionsButtonClick}
                data-cy="infusion-button"
              />
            </>
          ) : (
            <>
              <Button
                label=""
                className="sidebar-button"
                icon={<Records color={white} />}
                iconPlacement="left"
                title={'Records'}
              />
              <Button
                label=""
                className="sidebar-button"
                icon={<SyringeIcon fill={white} />}
                iconPlacement="left"
                title={'Infusion'}
              />
            </>
          )}
        </div>

        <div className="sidebar-footer">
          {sidebarDisplay === '' && globalUserState && (
            <span className="logged-in-user">
              {globalUserState.firstName} {globalUserState.lastName}
            </span>
          )}
          {sidebarDisplay === 'collapsed' &&
            globalUserState?.firstName &&
            globalUserState?.lastName && (
              <span className="logged-in-user">
                {globalUserState.firstName.split('')[0]}
                {globalUserState.lastName.split('')[0]}
              </span>
            )}

          {sidebarDisplay === '' && <OktaLogin />}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
