// colors
export const activeBlue: string = '#2cc0e8';
export const grey: string = '#c5c5c5';
export const lightGrey: string = '#e7e7e7';

export const neutral50: string = '#fbfbfb';
export const neutral100: string = '#f6f6f6';
export const neutral200: string = '#f1f1f1';
export const neutral300: string = '#e7e7e7';
export const neutral400: string = '#c5c5c5';
export const neutral600: string = '#7d7d7d';
export const neutral700: string = '#686868';
export const neutral800: string = '#494949';
export const neutral900: string = '#272727';

export const neutralGrey: string = '#494949';
export const primaryBlue: string = '#0091b2';
export const primaryBlue400: string = '#2cc0e8';
export const primaryBlue500: string = '#1ab5e1';
export const successGreen: string = '#359d36';
export const tealBlue: string = '#007e99';
export const warning: string = '#e7800b';
export const white: string = '#fff';
export const black: string = '#000';
