import React from 'react';

export const Check = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="17"
      viewBox="0 0 25 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1526 0.431008L9.33361 14.249C9.2407 14.3423 9.13028 14.4163 9.0087 14.4668C8.88712 14.5173 8.75676 14.5433 8.62511 14.5433C8.49345 14.5433 8.3631 14.5173 8.24151 14.4668C8.11993 14.4163 8.00952 14.3423 7.91661 14.249L2.57262 8.90001C2.47971 8.80674 2.36929 8.73272 2.24771 8.68222C2.12613 8.63172 1.99577 8.60572 1.86412 8.60572C1.73246 8.60572 1.6021 8.63172 1.48052 8.68222C1.35894 8.73272 1.24853 8.80674 1.15562 8.90001C1.06234 8.99292 0.988328 9.10334 0.937826 9.22492C0.887325 9.3465 0.861328 9.47686 0.861328 9.60851C0.861328 9.74017 0.887325 9.87052 0.937826 9.99211C0.988328 10.1137 1.06234 10.2241 1.15562 10.317L6.50161 15.662C7.06556 16.2249 7.82981 16.5411 8.62661 16.5411C9.42341 16.5411 10.1877 16.2249 10.7516 15.662L24.5696 1.84701C24.6627 1.75412 24.7366 1.64377 24.787 1.52228C24.8374 1.40079 24.8634 1.27054 24.8634 1.13901C24.8634 1.00747 24.8374 0.877231 24.787 0.755741C24.7366 0.634251 24.6627 0.5239 24.5696 0.431008C24.4767 0.337732 24.3663 0.263719 24.2447 0.213217C24.1231 0.162716 23.9927 0.136719 23.8611 0.136719C23.7294 0.136719 23.5991 0.162716 23.4775 0.213217C23.3559 0.263719 23.2455 0.337732 23.1526 0.431008Z"
        fill={props.fill}
      />
    </svg>
  );
};
