import './ToggleButton.scss';

interface ToggleButtonProps {
  label: string;
  onClick: () => void;
  active: boolean;
}

export function ToggleButton({
  label,
  onClick,
  active,
  ...rest
}: ToggleButtonProps) {
  const handleButtonClick = () => {
    onClick();
  };
  return (
    <div
      className="toggle-button-container"
      onClick={handleButtonClick}
      {...rest}
    >
      <div className={`button ${active ? 'active' : 'inactive'}`}>
        {label}
      </div>
    </div>
  );
}
