import { ViewIcon } from "../../icons/View";
import { Button } from "../Button/Button";
import "./Privacy.scss";

export interface PrivacyProps{ 
    resetPrivacy: () => void,
}

export function Privacy({resetPrivacy}:PrivacyProps){

    const handlePrivacyContainerClick = () => {
        resetPrivacy();
    }

    return (
        <div className="privacy-container">
            <Button
              label="Privacy Enabled. Click here to disable."
              className="privacy-button"
              icon={<ViewIcon width={30} height={30} />}
              iconPlacement="left"
              onClick={handlePrivacyContainerClick}
            />
                    
        </div>
    )
}