import { useCallback, useEffect, useState } from 'react';
import { domEventsForIdleTimeout } from '../../constants';

const useIdleTimer = () => {
  const [showIdlePopup, setShowIdlePopup] = useState<boolean>(false);
  const [showPrivacyOverlay, setShowPrivacyOverlay] =
    useState<boolean>(false);

  const [idleTimer, setIdleTimer] = useState<number>(0);
  const [privacyTimer, setPrivacyTimer] = useState<number>(0);

  const resetIdleTimer = () => {
    setIdleTimer(0);
  };

  const updateTimers = useCallback(() => {
    setIdleTimer((idleTimer) => idleTimer + 1);
    setPrivacyTimer((privacyTimer) => privacyTimer + 1);

    if (
      idleTimer >=
      Number(process.env.REACT_APP_MINUTES_TO_DISPLAY_IDLE_POPUP) * 60
    ) {
      setShowIdlePopup(true);
      setShowPrivacyOverlay(false);
    }

    if (
      privacyTimer >=
        Number(
          process.env.REACT_APP_SECONDS_TO_DISPLAY_PRIVACY_BLUR
        ) &&
      idleTimer <
        Number(process.env.REACT_APP_MINUTES_TO_DISPLAY_IDLE_POPUP) *
          60
    ) {
      setShowPrivacyOverlay(true);
    }
  }, [idleTimer, privacyTimer]);

  useEffect(() => {
    const intervalID = setInterval(updateTimers, 1000);

    return () => {
      window.clearInterval(intervalID);
    };
  }, [updateTimers]);

  const restartIdleTimer = useCallback(() => {
    setIdleTimer(0);
    if (
      privacyTimer <
      Number(process.env.REACT_APP_SECONDS_TO_DISPLAY_PRIVACY_BLUR)
    ) {
      setPrivacyTimer(0);
    }
  }, [privacyTimer]);

  const resetPrivacyTimer = useCallback(() => {
    setPrivacyTimer(0);
    setShowPrivacyOverlay(false);
  }, []);

  useEffect(() => {
    if (!showIdlePopup) {
      domEventsForIdleTimeout.forEach((e) =>
        window.addEventListener(e, restartIdleTimer)
      );
      return () => {
        domEventsForIdleTimeout.forEach((e) =>
          document.removeEventListener(e, restartIdleTimer)
        );
      };
    }

    // shut off all event listeners if this component is unmounted
    return () => {
      domEventsForIdleTimeout.forEach((e) =>
        window.removeEventListener(e, restartIdleTimer)
      );
    };
  }, [restartIdleTimer, showIdlePopup]);

  return {
    showIdlePopup,
    setShowIdlePopup,
    showPrivacyOverlay,
    setShowPrivacyOverlay,
    resetIdleTimer,
    resetPrivacyTimer,
  };
};

export default useIdleTimer;
