import { useEffect, useState } from 'react';
import { testTreatmentPlanList } from './testData';
import { Card } from '../../../components/Card/Card';
import { TreatmentPlan } from '../../../shared/types/types';
import { TreatmentPlansContent } from './content/TreatmentPlansContent';
import { TreatmentPlansList } from './plansList/TreatmentPlansList';
import './TreatmentPlans.scss';

export interface TreatmentPlansProps {
  patientId: string;
  data: TreatmentPlan[];
}

export function TreatmentPlans({ patientId }: TreatmentPlansProps) {
  const [expanded, setExpanded] = useState(true);
  const [treatmentPlanList, setTreatmentPlanList] = useState<
    TreatmentPlan[]
  >([]);
  const handleOnExpand = (expanded: boolean) => {
    setExpanded(expanded);
  };
  const [selectedTreatmentPlan, setSelectedTreatmentPlan] =
    useState<TreatmentPlan>();

  useEffect(() => {
    setTreatmentPlanList(testTreatmentPlanList);
  }, []);

  const handleSelectTreatmentPlan = (planId: string) => {
    const selectedPlan = treatmentPlanList.find(
      (plan) => plan._id === planId
    );

    setSelectedTreatmentPlan(selectedPlan);
  };

  const body = expanded ? (
    <>
      <div className="treatment-plans-container">
        <TreatmentPlansList
          onSelect={handleSelectTreatmentPlan}
          data={treatmentPlanList}
        />
        {selectedTreatmentPlan && (
          <TreatmentPlansContent planData={selectedTreatmentPlan} />
        )}
      </div>
    </>
  ) : (
    <></>
  );

  return (
    <div className="treatment-plans-card">
      <Card
        alignTitle="start"
        title={'PLAN INFORMATION'}
        body={body}
        expandable
        expanded={expanded}
        onExpand={handleOnExpand}
      />
    </div>
  );
}
