import './SetupNotes.scss';

export interface SetupNotesProps {}

export function SetupNotes(props: SetupNotesProps) {
  return (
    <div className="setup-notes-container" data-cy="setup-notes">
      TBD
    </div>
  );
}
