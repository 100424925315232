import { SpinnerIcon } from '../../../icons/Spinner';
import './LoadingSpinner.scss';

interface LoadingSpinnerProps {
  width?: number;
  height?: number;
  color?: string;
  classes?: string;
}

export function LoadingSpinner({
  color,
  width,
  height,
  classes,
  ...rest
}: LoadingSpinnerProps) {
  return (
    <div className={`spinner-icon rotate ${classes}`} {...rest}>
      <SpinnerIcon width={width} height={height} color={color} />
    </div>
  );
}
