import './TreatmentReport.scss';

export interface TreaatmentReportProps {}

export function TreatmentReport(props: TreaatmentReportProps) {
  return (
    <div
      className="treatment-report-container"
      data-cy="treatment-report"
    >
      TBD
    </div>
  );
}
