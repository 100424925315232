import { IconTypeProps } from '../../shared/types/types';
import '../../shared/styles/variables.scss';

export function AlignMiddle({
  width = 16,
  height = 16,
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_639_12073)">
        <path
          d="M0.666666 4.00002H15.3333C15.5101 4.00002 15.6797 3.92978 15.8047 3.80476C15.9298 3.67973 16 3.51016 16 3.33335C16 3.15654 15.9298 2.98697 15.8047 2.86195C15.6797 2.73692 15.5101 2.66669 15.3333 2.66669H0.666666C0.489855 2.66669 0.320286 2.73692 0.195262 2.86195C0.0702379 2.98697 0 3.15654 0 3.33335C0 3.51016 0.0702379 3.67973 0.195262 3.80476C0.320286 3.92978 0.489855 4.00002 0.666666 4.00002Z"
          fill={color}
        />
        <path
          d="M3.33335 6C3.15654 6 2.98697 6.07024 2.86195 6.19526C2.73692 6.32029 2.66669 6.48985 2.66669 6.66667C2.66669 6.84348 2.73692 7.01304 2.86195 7.13807C2.98697 7.26309 3.15654 7.33333 3.33335 7.33333H12.6667C12.8435 7.33333 13.0131 7.26309 13.1381 7.13807C13.2631 7.01304 13.3333 6.84348 13.3333 6.66667C13.3333 6.48985 13.2631 6.32029 13.1381 6.19526C13.0131 6.07024 12.8435 6 12.6667 6H3.33335Z"
          fill={color}
        />
        <path
          d="M12.6667 12.6667H3.33335C3.15654 12.6667 2.98697 12.7369 2.86195 12.8619C2.73692 12.987 2.66669 13.1565 2.66669 13.3334C2.66669 13.5102 2.73692 13.6797 2.86195 13.8048C2.98697 13.9298 3.15654 14 3.33335 14H12.6667C12.8435 14 13.0131 13.9298 13.1381 13.8048C13.2631 13.6797 13.3333 13.5102 13.3333 13.3334C13.3333 13.1565 13.2631 12.987 13.1381 12.8619C13.0131 12.7369 12.8435 12.6667 12.6667 12.6667Z"
          fill={color}
        />
        <path
          d="M15.3333 9.33331H0.666666C0.489855 9.33331 0.320286 9.40355 0.195262 9.52858C0.0702379 9.6536 0 9.82317 0 9.99998C0 10.1768 0.0702379 10.3464 0.195262 10.4714C0.320286 10.5964 0.489855 10.6667 0.666666 10.6667H15.3333C15.5101 10.6667 15.6797 10.5964 15.8047 10.4714C15.9298 10.3464 16 10.1768 16 9.99998C16 9.82317 15.9298 9.6536 15.8047 9.52858C15.6797 9.40355 15.5101 9.33331 15.3333 9.33331Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_639_12073">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
