import { IconTypeProps } from '../shared/types/types';

export function SortOutlinedDescending({
  width = '24',
  height = '24',
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1155_25163)">
        <path
          d="M5.12661 13.0112L18.8726 13.0112C19.2848 13.0115 19.688 13.1316 20.0332 13.3569C20.3783 13.5822 20.6505 13.903 20.8167 14.2802C20.9828 14.6575 21.0357 15.0748 20.969 15.4816C20.9022 15.8883 20.7186 16.2669 20.4406 16.5712L13.9536 23.1602C13.7012 23.4315 13.395 23.6472 13.0547 23.7937C12.7143 23.9402 12.3472 24.0141 11.9766 24.0109C11.6061 24.0077 11.2403 23.9274 10.9025 23.7751C10.5647 23.6228 10.2623 23.4018 10.0146 23.1262L3.58361 16.5982C3.29819 16.2965 3.1074 15.9177 3.0348 15.5088C2.9622 15.0999 3.01097 14.6786 3.17509 14.2971C3.33921 13.9156 3.6115 13.5905 3.95831 13.3619C4.30512 13.1334 4.71128 13.0115 5.12661 13.0112ZM11.9996 0.0211961C12.3742 0.0213469 12.7446 0.100412 13.0866 0.25324C13.4286 0.406069 13.7346 0.629232 13.9846 0.908195L20.4156 7.43219C20.7012 7.73379 20.8921 8.11246 20.9647 8.52139C21.0374 8.93032 20.9887 9.35158 20.8245 9.73309C20.6603 10.1146 20.388 10.4397 20.0411 10.6681C19.6942 10.8964 19.2879 11.0182 18.8726 11.0182L5.12661 11.0182C4.71462 11.018 4.31157 10.898 3.96653 10.6729C3.62149 10.4477 3.34933 10.1272 3.18318 9.75015C3.01704 9.37314 2.96407 8.95596 3.03072 8.5494C3.09738 8.14283 3.28078 7.76441 3.55861 7.46019L10.0456 0.871196C10.2944 0.601564 10.5967 0.386726 10.9331 0.240379C11.2695 0.0940313 11.6327 0.0193842 11.9996 0.0211961ZM18.8726 9.02119C18.8968 9.02108 18.9204 9.01397 18.9406 9.00073C18.9608 8.98748 18.9767 8.96866 18.9864 8.94655C18.9962 8.92444 18.9993 8.89998 18.9954 8.87614C18.9915 8.85229 18.9808 8.83008 18.9646 8.81219L12.5276 2.2782C12.4635 2.19967 12.383 2.13602 12.2919 2.09165C12.2007 2.04728 12.101 2.02324 11.9996 2.0212C11.9052 2.02075 11.8118 2.0404 11.7256 2.07885C11.6394 2.1173 11.5624 2.17367 11.4996 2.24419L5.00661 8.84419C4.99751 8.86073 4.99273 8.87931 4.99273 8.89819C4.99273 8.91707 4.99751 8.93565 5.00661 8.95219C5.01472 8.97541 5.03032 8.99528 5.05096 9.00868C5.07159 9.02207 5.09609 9.02823 5.12061 9.02619L18.8726 9.02119Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1155_25163">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
