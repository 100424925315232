import { TreatmentPlan } from "../../../shared/types/types";

export const testTreatmentPlanList: TreatmentPlan[] = [
  {
    _id: '123',
    approvals: {
      CAS: '2022-06-19T07:15:35.132Z',
      raystation: '2022-06-20T08:15:35.132Z',
    },
    plan: {
      type: 'patient',
      name: 'Barbara_Brain2Beam',
      courseName: 'Infusion2_1',
      prescriptionDose: '10 ml',
      status: 'active',
      treatmentSite: 'head',
      treatmentStatus: 'approved',
      beams: [
        {
          _id: '9987',
          name: 'First One',
          collimators: ['SM', 'MED', 'LG'],
          assumedBoron10: '160 mg/kg',
          monitorUnit: '10 MU',
        },
        {
          _id: '9987',
          name: 'Second One',
          collimators: ['SM', 'LG'],
          assumedBoron10: '180 mg/kg',
          monitorUnit: '12 MU',
        },
        {
          _id: '9987',
          name: 'Third One',
          collimators: ['XL'],
          assumedBoron10: '160 mg/kg',
          monitorUnit: '13 MU',
        },
      ],
    },
    patient: {
      lastName: 'Barbara',
      medicalId: '71MD3370',
    },
  },
  {
    _id: '234',
    approvals: {
      CAS: '2022-07-19T07:15:35.132Z',
      raystation: '2022-07-20T08:15:35.132Z',
    },
    plan: {
      type: 'patient',
      name: 'Bufford_Leg2Beam',
      courseName: 'Infusion3_1',
      prescriptionDose: '12 ml',
      status: 'active',
      treatmentSite: 'left leg',
      treatmentStatus: 'approved',
      beams: [
        {
          _id: '9984',
          name: 'FirstOne',
          collimators: ['MED', 'LG'],
          assumedBoron10: '130 mg/kg',
          monitorUnit: '14 MU',
        },
      ],
    },
    patient: {
      lastName: 'Bufford',
      medicalId: '21M71376',
    },
  },
  {
    _id: '345',
    approvals: {
      CAS: '2022-06-02T07:15:35.132Z',
      raystation: '2022-06-19T08:15:35.132Z',
    },
    plan: {
      type: 'patient',
      name: 'Rogers_Arm2Beam',
      courseName: 'Infusion3_1',
      prescriptionDose: '12 ml',
      status: 'active',
      treatmentSite: 'left arm',
      treatmentStatus: 'approved',
      beams: [
        {
          _id: '9985',
          name: 'NextToLast',
          collimators: ['LG', 'XL'],
          assumedBoron10: '120 mg/kg',
          monitorUnit: '13 MU',
        },
      ],
    },
    patient: {
      lastName: 'Rogers',
      medicalId: '56G72805',
    },
  },
  {
    _id: '456',
    approvals: {
      CAS: '2022-05-02T08:15:25.132Z',
      raystation: '2022-05-19T09:15:15.132Z',
    },
    plan: {
      type: 'patient',
      name: 'Selters_Neck2Beam',
      courseName: 'Infusion3_1',
      prescriptionDose: '12 ml',
      status: 'active',
      treatmentSite: 'neck',
      treatmentStatus: 'approved',
      beams: [
        {
          _id: '9986',
          name: 'FirstAndOnly',
          collimators: ['LG', 'XL'],
          assumedBoron10: '114 mg/kg',
          monitorUnit: '11 MU',
        },
      ],
    },
    patient: {
      lastName: 'Selters',
      medicalId: '46G76813',
    },
  },
];