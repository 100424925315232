import './Row.scss';
import React, { ReactNode } from 'react';

export interface RowProps {
  children?: ReactNode;
  index: number;
  style?: object;
  classes?: string;
  selected?: boolean;
  onClick?: () => void;
}

export function Row({
  children,
  index,
  selected,
  style,
  classes,
  onClick,
  ...rest
}: RowProps) {
  return (
    <tr
      className={`row-container ${classes} ${
        selected
          ? 'row-selected'
          : index % 2 === 1
          ? 'row-alternate-bg'
          : ''
      }`}
      style={style}
      onClick={onClick}
      {...rest}
    >
      {children}
    </tr>
  );
}
