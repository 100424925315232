import { TreatmentPlansSection } from '../TreatmentPlansContent';
import { ToggleButton } from './ToggleButton';
import './TreatmentPlansNavBar.scss';

export interface TreatmentPlansNavBarProps {
  onPlanOverviewClick: () => void;
  onSetupNotesClick: () => void;
  onTreatmentReportClick: () => void;
  currentSection: TreatmentPlansSection;
}
export function TreatmentPlansNavBar({
  onPlanOverviewClick,
  onSetupNotesClick,
  onTreatmentReportClick,
  currentSection,
}: TreatmentPlansNavBarProps) {
  const handlePlanOverviewClick = () => {
    onPlanOverviewClick();
  };

  const handleSetupNotesClick = () => {
    onSetupNotesClick();
  };

  const handleTreatmentReportClick = () => {
    onTreatmentReportClick();
  };

  return (
    <div
      className="treatment-plans-nav-bar-container"
      data-cy="treatment-plans-nav-bar"
    >
      <ToggleButton
        label={'Plan Overview'}
        onClick={handlePlanOverviewClick}
        active={currentSection === 'plans-overview'}
        data-cy="plan-overview-button"
      />
      <ToggleButton
        label={'Setup Notes'}
        onClick={handleSetupNotesClick}
        active={currentSection === 'setup-notes'}
        data-cy="setup-notes-button"
      />
      <ToggleButton
        label={'Treatment Report'}
        onClick={handleTreatmentReportClick}
        active={currentSection === 'treatment-report'}
        data-cy="treatment-report-button"
      />
    </div>
  );
}
