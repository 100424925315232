import { Editor, EditorState, RichUtils } from 'draft-js';
import React, { ReactNode, useState } from 'react';
import './WysiwygEditor.scss';
import 'draft-js/dist/Draft.css';
import { StyleControlButton } from './StyleControlButton';
import { Bold } from '../../icons/editorControls/Bold';
import { Italics } from '../../icons/editorControls/Italics';
import { Underline } from '../../icons/editorControls/Underline';
import { AlignLeft } from '../../icons/editorControls/AlignLeft';
import { AlignMiddle } from '../../icons/editorControls/AlignMiddle';
import { AlignRight } from '../../icons/editorControls/AlignRight';

export interface WysiwygEditorProps {
  editorState: EditorState;
  setEditorState: (editorState: EditorState) => void;
  readOnly?: boolean;
}

export function WysiwygEditor({
  editorState,
  setEditorState,
  readOnly,
}: WysiwygEditorProps) {
  const [textAlign, setTextAlign] = useState<AlignType>('left');

  // ********* BLOCK TYPES *********
  //

  const BLOCK_TYPES = [
    { label: 'H1', style: 'header-one' },
    { label: 'H2', style: 'header-two' },
    { label: 'H3', style: 'header-three' },
    { label: 'H4', style: 'header-four' },
    { label: 'H5', style: 'header-five' },
    { label: 'H6', style: 'header-six' },
    { label: 'Blockquote', style: 'blockquote' },
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
    { label: '<..>', style: 'code-block' },
  ];

  const handleToggleBlockType = (style: string) => {
    setEditorState(RichUtils.toggleBlockType(editorState, style));
  };

  const BlockStyleControls = ({
    editorState,
    onToggle,
  }: StyleControlsProps) => {
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    return (
      <div className="control-group">
        {BLOCK_TYPES.map((type) => (
          <StyleControlButton
            key={type.label}
            active={type.style === blockType}
            icon={getIcon(type.label)}
            onToggle={onToggle}
            style={type.style}
            label={type.label}
          />
        ))}
      </div>
    );
  };

  // ********* INLINE STYLES *********
  //

  const INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ];

  const handleToggleInlineStyles = (style: string) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const InlineStyleControls = ({
    editorState,
    onToggle,
  }: StyleControlsProps) => {
    const currentStyle = editorState.getCurrentInlineStyle();

    return (
      <div className="control-group">
        {INLINE_STYLES.map((type) => (
          <StyleControlButton
            key={type.label}
            active={currentStyle.has(type.style)}
            icon={getIcon(type.label)}
            onToggle={onToggle}
            style={type.style}
            label={type.label}
          />
        ))}
      </div>
    );
  };

  // ********* TEXT ALIGN *********
  //

  const TEXT_ALIGN_TYPES = [
    { label: 'Left', style: 'left' },
    { label: 'Center', style: 'center' },
    { label: 'Right', style: 'right' },
  ];

  const handleTextAlign = (align: string) => {
    setTextAlign(align as AlignType);
  };

  const TextAlignControls = ({ onToggle }: StyleControlsProps) => {
    return (
      <div className="control-group">
        {TEXT_ALIGN_TYPES.map((type) => (
          <StyleControlButton
            key={type.label}
            active={textAlign === type.style}
            icon={getIcon(type.label)}
            onToggle={onToggle}
            style={type.style}
            label={type.label}
          />
        ))}
      </div>
    );
  };

  const getIcon = (key: string): ReactNode | null => {
    switch (key) {
      case 'Bold':
        return <Bold />;
      case 'Italic':
        return <Italics />;
      case 'Underline':
        return <Underline />;
      case 'Left':
        return <AlignLeft />;
      case 'Center':
        return <AlignMiddle />;
      case 'Right':
        return <AlignRight />;
      default:
        return null;
    }
  };

  return (
    <div
      className={`wysiwyg-container ${readOnly ? 'readOnly' : ''}`}
    >
      {!readOnly && (
        <div className={'wysiwyg-controls'}>
          <InlineStyleControls
            editorState={editorState}
            onToggle={handleToggleInlineStyles}
          />
          <BlockStyleControls
            editorState={editorState}
            onToggle={handleToggleBlockType}
          />
          <TextAlignControls
            editorState={editorState}
            onToggle={handleTextAlign}
          />
        </div>
      )}
      <Editor
        editorState={editorState}
        onChange={setEditorState}
        placeholder={readOnly ? '' : 'Add note'}
        // hacky, I know, but draft-js didn't export their DraftTextAlignment interface
        // @ts-ignore: Unreachable code error
        textAlignment={textAlign}
        readOnly={readOnly}
      />
    </div>
  );
}

type AlignType = 'left' | 'right' | 'center';

interface StyleControlsProps {
  editorState: EditorState;
  onToggle: (style: string) => void;
}
