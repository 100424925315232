import './Card.scss';

/*@desc what this task is */
import { CardProps } from '../../shared/types/types';
import { ToggleExpand } from '../ToggleExpand/ToggleExpand';
export const Card = ({
  title,
  body,
  alignTitle,
  expandable = false,
  expanded = false,
  onExpand = () => {},
}: CardProps) => {
  const titleAlignment = !alignTitle ? 'start' : alignTitle;

  const handleToggleExpand = () => {
    const newExpanded = !expanded;
    onExpand(newExpanded);

    if (!onExpand) return;
    onExpand(newExpanded);
  };

  return (
    <div className="card">
      <div className="card-header">
        <div
          className="card-title-top"
          style={{
            alignSelf: titleAlignment,
          }}
        >
          {title.toUpperCase()}
        </div>
        {expandable && (
          <ToggleExpand
            open={expanded}
            onClick={handleToggleExpand}
          />
        )}
      </div>
      <div className="card-body">{body}</div>
    </div>
  );
};
