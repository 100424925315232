import { format } from 'date-fns';
import { useState } from 'react';
import { Button } from '../../../../../components/Button/Button';
import { AlertIcon } from '../../../../../icons/AlertIcon';
import { Edit } from '../../../../../icons/Edit';
import { Floppy } from '../../../../../icons/Floppy';
import { XClose } from '../../../../../icons/XClose';
import {
  Beam,
  TreatmentPlan,
} from '../../../../../shared/types/types';
import { HorizontalRule } from '../common/HorizontalRule';
import './PlanOverview.scss';

export type TreatmentStatusType = 'Active' | 'Completed';

export interface PlanOverviewProps {
  planData: TreatmentPlan;
  onStatusChange: (status: TreatmentStatusType) => void;
  onCourseNameChange: (name: string) => void;
  onApproveClick: () => void;
  onUnapproveClick: () => void;
}

export function PlanOverview({
  planData,
  onStatusChange,
  onCourseNameChange,
  onApproveClick,
  onUnapproveClick,
}: PlanOverviewProps) {
  const [editing, setEditing] = useState(false);
  const [awaitingApproval, setAwaitingApproval] = useState(false);
  const [casApproval, setCASApproval] = useState<string | null>(
    planData.approvals.CAS
  );
  const [courseName, setCourseName] = useState(
    planData.plan.courseName
  );
  const [courseNameEdit, setCourseNameEdit] = useState('');

  const handleCourseNameEditChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCourseNameEdit(e.target.value);
  };
  const handleEditClick = () => {
    setEditing(true);
  };

  const handleSaveClick = () => {
    setEditing(false);
    setAwaitingApproval(true);
    setCASApproval(null);
    setCourseName(courseNameEdit);
    setCourseNameEdit(courseNameEdit);
  };

  const handleCancelClick = () => {
    setEditing(false);
    setCourseNameEdit('');
  };

  const handleApproveClick = () => {
    setAwaitingApproval(false);
    setCASApproval(new Date().toISOString());
  };

  return (
    <div className="plan-overview-container" data-cy="plan-overview">
      <div className="title">
        Plan Name: {planData?.plan?.name ?? 'ERROR'}
      </div>
      <div className="plan-data">
        <div className="data-group">
          <div className="label-and-data">
            <div className="label">Plan Type:</div>
            <div className="data">
              {planData?.plan?.type ?? 'ERROR'}
            </div>
          </div>
          <div className="label-and-data">
            <div className="label">Plan Status:</div>
            <div className="data">
              {planData?.plan?.status ?? 'ERROR'}
            </div>
          </div>
          <div className="label-and-data">
            <div className="label">Prescription Dose:</div>
            <div className="data">
              {planData?.plan?.prescriptionDose ?? 'ERROR'}
            </div>
          </div>
        </div>
        <div className="data-group">
          <div className="label-and-data">
            <div className="label">Treatment Site</div>
            <div className="data">
              {planData?.plan?.treatmentSite ?? 'ERROR'}
            </div>
          </div>
          <div className="label-and-data">
            <div className="label">Course Name:</div>
            {editing ? (
              <input
                value={courseNameEdit}
                onChange={handleCourseNameEditChange}
                className={'input'}
                placeholder={courseName}
                data-cy="course-name-edit-field"
              />
            ) : (
              <div className="data">{courseName}</div>
            )}
          </div>
          <div className="label-and-data">
            <div className="label">Treatment Status:</div>
            <div className="data">
              {planData?.plan?.treatmentStatus ?? 'ERROR'}
            </div>
          </div>
        </div>
      </div>
      <HorizontalRule dashed />
      <div className="subtitle">BEAMS</div>
      {planData?.plan?.beams.map((data: Beam, index: number) => (
        <div className="beam-data" key={`${data._id}_${index}`}>
          <div className="data-group">
            <div className="label-and-data">
              <div className="label">Beam Name:</div>
              <div className="data">{data.name}</div>
            </div>
            <div className="label-and-data">
              <div className="label">Collimators:</div>
              <div className="data">
                {data.collimators.join(', ')}
              </div>
            </div>
          </div>
          <div className="data-group">
            <div className="label-and-data">
              <div className="label">Assumed Boron 10:</div>
              <div className="data">{data.assumedBoron10}</div>
            </div>
            <div className="label-and-data">
              <div className="label">Monitor Unit:</div>
              <div className="data">{data.monitorUnit}</div>
            </div>
          </div>
        </div>
      ))}

      <HorizontalRule dashed />
      <div className="subtitle">approvals </div>
      <div className="plan-data">
        <div className="data-group">
          <div className="label-and-data">
            <div className="label">Raystation approval:</div>
            <div className="data">
              {format(
                new Date(planData?.approvals?.raystation ?? 'ERROR'),
                'dd-MMM-yyyy HH:mm:ss'
              )}
            </div>
          </div>
          <div className="label-and-data">
            <div className="label">CAS approval:</div>
            <div
              className={`data ${
                casApproval == null ? 'approval-required' : ''
              }`}
              data-cy="cas-approval-field"
            >
              {casApproval != null
                ? format(
                    new Date(casApproval),
                    'dd-MMM-yyyy HH:mm:ss'
                  )
                : 'REQUIRES APPROVAL'}
            </div>
          </div>
        </div>
      </div>
      <div className="controls">
        {awaitingApproval && (
          <Button
            label="Approve"
            icon={<AlertIcon />}
            iconPlacement={'left'}
            className={'approve-button'}
            onClick={handleApproveClick}
            data-cy="approve-button"
          />
        )}
        {!editing && (
          <Button
            label={'Edit'}
            icon={<Edit />}
            iconPlacement={'left'}
            className={'edit-button'}
            onClick={handleEditClick}
            data-cy="edit-button"
          />
        )}
        {editing && (
          <>
            <Button
              label={'Save'}
              icon={<Floppy />}
              iconPlacement={'left'}
              className={'save-button'}
              onClick={handleSaveClick}
              data-cy="save-button"
            />
            <Button
              label={'Cancel'}
              icon={<XClose />}
              iconPlacement={'left'}
              className={'cancel-button'}
              onClick={handleCancelClick}
              data-cy="cancel-button"
            />
          </>
        )}
      </div>
    </div>
  );
}
