import React from 'react';

export const CloudIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="23.959805"
      height="21.975796"
      viewBox="0 0 23.959805 21.975796"
      fill="none"
      version="1.1"
      id="svg68"
    >
      <path
        d="m 17.788964,6.187792 c -0.4444,-1.91072 -1.5754,-3.59156 -3.1779,-4.72302 -1.6025,-1.13145704 -3.565,-1.63466304 -5.5142098,-1.41396414 -1.94926,0.2206991 -3.74949,1.14991414 -5.05848,2.61102414 -1.309,1.46111 -2.03552,3.35225 -2.04146,5.31396 -8.7e-4,1.27839 0.30794,2.538 0.9,3.671 -1.07888,0.5781 -1.93360002,1.5002 -2.42839002,2.6198 -0.494777,1.1195 -0.60123,2.3723 -0.302443,3.5593 0.298783,1.187 0.98567302,2.2401 1.95153302,2.992 0.96586,0.7519 2.15528,1.1594 3.3793,1.1579 H 15.996964 c 1.959,-0.0091 3.8467,-0.7367 5.305,-2.045 1.4583,-1.3082 2.3858,-3.1061 2.6068,-5.0527 0.2209,-1.9466 -0.2801,-3.9066 -1.4081,-5.50839 -1.128,-1.60178 -2.8045,-2.73398 -4.7117,-3.18191 z"
        fill="#359d36"
        id="path66"
      />
      <g id="layer1" transform="translate(-0.56933582,-0.02420804)">
        <g
          style={{ fill: `${props.fill}` }}
          id="g228"
          transform="translate(6.6576691,10.203454)"
        >
          <path
            d="M 11.7257,0.2155 4.81622,7.1245 C 4.76976,7.17114 4.71456,7.20815 4.65377,7.2334 4.59297,7.25865 4.52779,7.27165 4.46197,7.27165 4.39614,7.27165 4.33096,7.25865 4.27017,7.2334 4.20938,7.20815 4.15417,7.17114 4.10772,7.1245 L 1.43572,4.45 C 1.38927,4.40336 1.33406,4.36636 1.27327,4.34111 1.21248,4.31586 1.1473,4.30286 1.08147,4.30286 c -0.06582,0 -0.131004,0.013 -0.191795,0.03825 C 0.828884,4.36636 0.773677,4.40336 0.727223,4.45 0.680584,4.49646 0.643578,4.55166 0.618327,4.61246 0.593076,4.67325 0.580078,4.73843 0.580078,4.80425 c 0,0.06583 0.012998,0.13101 0.038249,0.1918 0.025251,0.06079 0.062257,0.116 0.108896,0.16245 L 3.40022,7.831 c 0.28197,0.28145 0.6641,0.43953 1.0625,0.43953 0.3984,0 0.78052,-0.15808 1.0625,-0.43953 L 12.4342,0.923501 C 12.4808,0.877055 12.5177,0.821879 12.5429,0.761134 12.5681,0.700389 12.5811,0.635268 12.5811,0.5695 12.5811,0.503733 12.5681,0.438612 12.5429,0.377867 12.5177,0.317121 12.4808,0.261946 12.4342,0.2155 12.3878,0.168862 12.3325,0.131856 12.2718,0.106605 12.211,0.081354 12.1458,0.0683556 12.08,0.0683556 c -0.0659,0 -0.131,0.0129984 -0.1918,0.0382494 -0.0608,0.025251 -0.116,0.062257 -0.1625,0.108895 z"
            fill="#fbfbfb"
            id="path219"
          />
        </g>
      </g>
    </svg>
  );
};
