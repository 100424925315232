import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { Logout } from '../../shared/assets/logout';
import './Authentication.scss';
import { GlobalUserContext } from '../../shared/contexts/GlobalUserContext';
import { Button } from '../Button/Button';
import { useAuth0, IdToken } from '@auth0/auth0-react';

const OktaLogin = () => {
  const [authState, setAuthState] = useState<IdToken | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const {
    isAuthenticated,
    logout,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  const globalUserState = useContext(GlobalUserContext);

  const getIDtoken = useCallback(async () => {
    const tokenClaim = await getIdTokenClaims();
    tokenClaim && setAuthState(tokenClaim);
  }, [getIdTokenClaims]);

  const getSilentToken = useCallback(async () => {
    try {
      const tokenClaim = await getAccessTokenSilently();
      tokenClaim && setToken(tokenClaim);
    } catch (error) {
      // To-do Error handling for not authenticated from SAML
    }
  }, [getAccessTokenSilently]);

  const setAuthContext = useCallback(() => {
    if (!isAuthenticated || !authState) {
      globalUserState?.setIsLoggedIn?.(false);
      globalUserState?.setFirstName?.('');
      globalUserState.setLastName?.('');
      globalUserState.setUserName?.('');
      globalUserState.setToken?.('');
    } else {
      globalUserState.setIsLoggedIn?.(true);
      globalUserState.setFirstName?.(authState?.name?.split(' ')[0]);
      globalUserState.setLastName?.(authState?.name?.split(' ')[1]);
      globalUserState.setUserName?.(authState?.email);
      globalUserState.setToken?.(authState?.__raw);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, isAuthenticated]);

  const logOut = () => {
    globalUserState?.setIsLoggedIn?.(false);
    globalUserState?.setFirstName?.('');
    globalUserState.setLastName?.('');
    globalUserState.setUserName?.('');
    logout({ returnTo: window.location.origin });
  };

  useEffect(() => {
    getSilentToken();
    getIDtoken();
    setAuthContext();
  }, [
    getIDtoken,
    getIdTokenClaims,
    getSilentToken,
    isAuthenticated,
    setAuthContext,
    token,
  ]);

  if (!authState) {
    return <div>Loading authentication...</div>;
  } else {
    return (
      <div className="okta-logout" onClick={logOut}>
        <Button
          label="Logout"
          className="logout-button"
          icon={<Logout fill="#fff" />}
          iconPlacement="left"
        />
      </div>
    );
  }
};
export default OktaLogin;
