import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import {
  GlobalUserStateInterface,
  UserContextProps,
} from '../types/types';

export const initialState: GlobalUserStateInterface = {
  firstName: '',
  setFirstName: () => {},
  lastName: '',
  setLastName: () => {},
  userName: '',
  setUserName: () => {},
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  token: '',
  setToken: () => {},
  patientInfo: null,
  setPatientInfo: () => {},
  clearPatientInfo: () => {},
};

export const GlobalUserContext =
  createContext<GlobalUserStateInterface>(initialState);

export const GlobalStateProvider: React.FC<UserContextProps> = ({
  children,
}) => {
  const [firstName, setFirstName] = useState(initialState.firstName);
  const [lastName, setLastName] = useState(initialState.lastName);
  const [userName, setUserName] = useState(initialState.userName);
  const [isLoggedIn, setIsLoggedIn] = useState(
    initialState.isLoggedIn
  );
  const [token, setToken] = useState(initialState.token);
  const [patientInfo, setPatientInfo] = useState(
    initialState.patientInfo
  );

  const clearPatientInfo = useCallback(() => {
    setPatientInfo(null);
  }, []);
  return (
    <GlobalUserContext.Provider
      value={useMemo(
        () => ({
          firstName,
          setFirstName,
          lastName,
          setLastName,
          userName,
          setUserName,
          isLoggedIn,
          setIsLoggedIn,
          token,
          setToken,
          patientInfo,
          setPatientInfo,
          clearPatientInfo,
        }),
        [
          firstName,
          isLoggedIn,
          lastName,
          userName,
          token,
          patientInfo,
          setPatientInfo,
          clearPatientInfo,
        ]
      )}
    >
      {children}
    </GlobalUserContext.Provider>
  );
};
