import React from 'react';
import clsx from 'clsx';
import './TextInput.scss';

export interface TextInputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value: string;
  placeHolder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  highlightBorder?: boolean;
  width?: number;
  className?: string;
  borderColor?: string;
}

export function TextInput({
  onChange,
  onKeyDown,
  value,
  placeHolder,
  disabled,
  readOnly,
  highlightBorder,
  width,
  className,
  borderColor,
}: TextInputProps) {
  return (
    <div
      className={clsx(
        `text-input-container`,
        disabled && 'disabled',
        readOnly && 'readOnly',
        highlightBorder && 'highlightBorder',
        className
      )}
      style={
        width ? { width: width, borderColor: borderColor } : undefined
      }
    >
      <input
        className={clsx(
          `text-input`,
          disabled && 'disabled',
          readOnly && 'readOnly'
        )}
        type="text"
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeHolder}
        disabled={disabled}
        readOnly={readOnly}
      />
    </div>
  );
}
