import clsx from "clsx";
import "./FlexContainer.scss";

import { FlexContainerProps } from "../../shared/types/types";
export const FlexContainer = ({
  alignContent,
  direction,
  alignItems,
  justifyContent,
  className,
  style,
  children,
}: FlexContainerProps) => {
  const flexAlignItems = !alignItems ? "center" : alignItems;
  const flexDirection = !direction ? "row" : direction;
  const flexAlignContent = !alignContent ? "flex-start" : alignContent;
  const flexJustifyContent = !justifyContent ? "center" : justifyContent;
  return (
    <div
      className={clsx("flex-container", className)}
      style={{
        flexDirection: flexDirection,
        alignItems: flexAlignItems,
        alignContent: flexAlignContent,
        justifyContent: flexJustifyContent,
        ...style,
      }}
    >
      {children}
    </div>
  );
};
